import { enterAction, exitAction } from "./actionFunctions";
import { patchTransaction } from "./transactionFunctions";
import { scanYieldBalancesForNetwork } from "./yieldFunctions";

const networkNames = [
    "arbitrum",
    "optimism",
    "base",
    "avalanche-c",
]

export const nativeTokens = ["ETH", "AVAX"]

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const processYieldOpps = (opps) => {
    let yieldOppMap = {};

    opps.forEach((opp) => {
        yieldOppMap[opp.id] = {
            token: opp.token,
            apy: opp.apy,
            name: opp.metadata.name
        }
    });

    return yieldOppMap;
}

export const scanYieldBalances = async (walletAddress) => {
    try {
        const resultPromises = networkNames.map((network) => {
            return scanYieldBalancesForNetwork(walletAddress, network);
        });

        const results = await Promise.all(resultPromises);

        return results;
    } catch (error) {
        console.error('Error entering yield opportunity:', error);
        return error;
    }
}

export const processYieldBalances = (yieldOppMap, yieldBalances) => {
    // console.log("Yield Opp Map: ", yieldOppMap);
    try {
        let tokenYieldMap = {};

        const flattenedList = yieldBalances.flat();

        flattenedList.forEach((integration) => {
            const integrationId = integration.integrationId;

            // console.log("Integration: ", integration);
            // console.log("Integration ID: ", integrationId);

            const integrationBalances = integration.balances;
            const filteredIntegrationBalances = integrationBalances.filter((balance) => parseFloat(balance.amount) > 0);

            // console.log("Filtered Integration Balances: ", filteredIntegrationBalances);

            if (filteredIntegrationBalances.length == 0) {
                return;
            }

            const oppDetails = yieldOppMap[integrationId];

            // console.log("Opp Details: ", oppDetails);

            const oppUnderlyingTokenKey = oppDetails.token.symbol;

            // console.log("Opp Underlying Token Key: ", oppUnderlyingTokenKey);

            if (tokenYieldMap[oppUnderlyingTokenKey]) {
                const primaryStakedAssets = integrationBalances.find((balance) => balance.type === "staked");
                const rewardAssets = integrationBalances.find((balance) => balance.type === "rewards");

                const underlyingValue = parseFloat(primaryStakedAssets.amount) * parseFloat(primaryStakedAssets.pricePerShare);
                tokenYieldMap[oppUnderlyingTokenKey].opportunities[integrationId] = {
                    apy: oppDetails.apy,
                    tokens: integrationBalances,
                    primaryStakedAssetDetails: primaryStakedAssets,
                    rewardAssets: rewardAssets,
                    underlyingValue,
                    network: oppDetails?.token?.network,
                    name: oppDetails?.metadata?.name
                }
            } else {
                const primaryStakedAssets = integrationBalances.find((balance) => balance.type === "staked");
                const rewardAssets = integrationBalances.find((balance) => balance.type === "rewards");

                const underlyingValue = parseFloat(primaryStakedAssets.amount) * parseFloat(primaryStakedAssets.pricePerShare)

                tokenYieldMap[oppUnderlyingTokenKey] = {
                    opportunities: {
                        [integrationId]: {
                            apy: oppDetails.apy,
                            tokens: integrationBalances,
                            primaryStakedAssetDetails: primaryStakedAssets,
                            rewardAssets: rewardAssets,
                            underlyingValue,
                            network: oppDetails?.token?.network,
                            name: oppDetails?.metadata?.name
                        }
                    },
                    tokenDetails: oppDetails?.token
                }
            }
        });
        return tokenYieldMap;
    } catch (e) {
        console.error("ERROR: ", e);
        return e;
    }
}

export const enterYieldOpportunity = async ({
    integrationId,
    addresses,
    args
}) => {
    try {
        const response = await enterAction({
            integrationId,
            addresses,
            args
        });

        const patchTxnResults = response.transactions.map(async (transaction) => {
            await delay(1000); // Add a 1 second delay
            return patchTransaction(transaction.id);
        });
        const results = await Promise.all(patchTxnResults);

        const txnData = results.map((txn) => {
            const txnObj = JSON.parse(txn.unsignedTransaction);
            return {
                targetContractAddress: txnObj.to,
                value: txnObj.value,
                calldata: txnObj.data,
                chainId: txnObj.chainId,
                walletAddress: addresses.address,
                label: txn.type,
            }
        });
        return txnData;
    } catch (error) {
        console.error('Error entering yield opportunity:', error);
        throw error;
    }
}

export const exitYieldOpportunity = async ({
    integrationId,
    addresses,
    args
}) => {
    try {
        const response = await exitAction({
            integrationId,
            addresses,
            args
        });

        const patchTxnResults = response.transactions.map(async (transaction) => {
            await delay(1000); // Add a 1 second delay
            return patchTransaction(transaction.id);
        });
        const results = await Promise.all(patchTxnResults);

        const txnData = results.map((txn) => {
            const txnObj = JSON.parse(txn.unsignedTransaction);
            return {
                targetContractAddress: txnObj.to,
                value: txnObj.value,
                calldata: txnObj.data,
                chainId: txnObj.chainId,
                walletAddress: addresses.address,
                label: txn.type,
            }
        });
        return txnData;
    } catch (error) {
        console.error('Error entering yield opportunity:', error);
        throw error;
    }
}