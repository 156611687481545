import { LogoCompact } from "./logo-compact";
import styles from "./css/connect.module.css";
import { CircularProgress } from "@mui/material";
import { LogIn } from "lucide-react";

export default function ConnectWalletBtn(props) {
  const handelClick = (e) => {
    e.preventDefault();
    if (props.state === "disconnected") {
      props.setState("connecting");
      setTimeout(() => {
        props.setNameBoxOpen(true);
      }, 200);
    } else if (props.state === "connected") {
      setTimeout(() => {
        props.setWalletOpen(!props.walletOpen);
      }, 200);
    }
  };
  return (
    <button onMouseDown={handelClick} className={styles.main}>
      {props.state === "disconnected" && (
        <>
          <LogIn size={24} />
          <h1>Sign In</h1>
        </>
      )}
      {props.state === "connecting" && (
        <>
          <CircularProgress
            size={20}
            color="inherit"
            style={{ color: "#1a1a1a" }}
          />
          <h1>Signing In</h1>
        </>
      )}
      {props.state === "connected" && (
        <>
          <img
            src={`https://api.dicebear.com/7.x/bottts-neutral/svg?seed=${props.userData.wallet?.scw_address}`}
            alt="avatar"
            style={{ width: "28px", height: "28px", borderRadius: "50%" }}
          />
          <div>
            <h2>{props.userData.username}</h2>
            <p>
              {props.userData.wallet?.scw_address.slice(0, 6) +
                "..." +
                props.userData.wallet?.scw_address.slice(-4)}
            </p>
          </div>
        </>
      )}
    </button>
  );
}
