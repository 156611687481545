import axios from "axios";

const headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Authorization', `${process.env.REACT_APP_ENCLAVE_SDK_KEY}`);

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getStakeOpportunities = async () => {
    const result = await axios.get(BASE_URL+'/yield-opportunities', {
        headers: {
            "Content-Type": 'application/json',
            'Authorization': process.env.REACT_APP_ENCLAVE_SDK_KEY
        }
    });

    return result.data;
}

export const getHotTokens = async () => {
    const result = await axios.get(BASE_URL+'/hot-tokens', {
        headers: {
            "Content-Type": 'application/json',
            'Authorization': process.env.REACT_APP_ENCLAVE_SDK_KEY
        }
    });

    return result.data;
}

export const getAllTokens = async () => {
    const result = await axios.get(BASE_URL+'/all-tokens', {
        headers: {
            "Content-Type": 'application/json',
            'Authorization': process.env.REACT_APP_ENCLAVE_SDK_KEY
        }
    });

    return result.data;
}

export const getNativeTokenPriceData = async () => {
    const result = await axios.get(BASE_URL+'/native-token-prices', {
        headers: {
            "Content-Type": 'application/json',
            'Authorization': process.env.REACT_APP_ENCLAVE_SDK_KEY
        }
    });

    return result.data;
}