import React from 'react';
import { useEnclaveApi } from '../../EnclaveSDK/context/EnclaveConnectProvider';
import { CryptoElements, OnrampElement } from '../../StripeCryptoElements';
import styles from "../../css/onramp.module.css";
import isPWA from "../../../utils/pwaUtils";
import { useRef, useEffect } from 'react';

export const StripePopUp = (props) => {

    const bg = useRef(null);
    const {stripeOnRamp} = useEnclaveApi();

    useEffect(() => {
		// bg.current.addEventListener("click", (e) => {
		// 	if (e.target === bg.current) {
        //         props.setShowStripePopUp(false);
        //         props.setStripeSessionLoading(false);
		// 	}
		// });
	}, []);


    return (
			<div className={`${styles.popUp} ${isPWA() ? styles.popUpOverrides : ""}`}>
                <CryptoElements stripeOnramp={stripeOnRamp}>
                    <OnrampElement clientSecret={props.clientSecret} appearance={{theme: "dark"}} />
                </CryptoElements>
            </div>
    )
}