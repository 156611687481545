import React, { forwardRef, useImperativeHandle } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  LineController,
} from "chart.js";
import { Chart, Line } from "react-chartjs-2";
import styles from "../../css/chart.module.css";

// Register the components we'll be using
ChartJS.register(
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler // Add Filler for area charts
);

const ChartJSFilledLineChart = forwardRef(
  ({ priceData, labels, onHover, onMouseLeave }, ref) => {
    const data = {
      labels: labels,
      datasets: [
        {
          //   label: 'Monthly Values',
          data: priceData,
          borderColor: "#fcff61",
          backgroundColor: "#fcff6120", // Color for the area
          tension: 0.1,
          fill: true, // Enable filling the area under the line
        },
      ],
    };

    const verticalLinePlugin = {
      id: "verticalLine",
      afterDraw: (chart, args, options) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const activePoint = chart.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.element.x;
          const topY = chart.scales.y.top;
          const bottomY = chart.scales.y.bottom;

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "white";
          ctx.stroke();
          ctx.restore();
        }
      },
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false, // This ensures the legend is not displayed
        },
        tooltip: {
          enabled: false, // Disable default tooltip
        },
        verticalLine: {},
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            callback: (value, index, values) => {
              // Show only the first and last tick
              //   return index === 0 || index === (values.length - 1) ? value : '';
              return "";
            },
          },
        },
        y: {
          display: false,
          beginAtZero: false,
        },
      },
      elements: {
        point: {
          radius: 0,
          hoverRadius: 5,
          hoverBackgroundColor: "#3466ea",
        },
        line: {
          borderWidth: 2,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      onHover: (event, chartElements) => {
        if (chartElements.length > 0) {
          const dataIndex = chartElements[0].index;
          onHover(priceData[dataIndex], dataIndex);
        }
      },
    };

    useImperativeHandle(ref, () => ({
      // You can add methods here if needed
    }));

    return (
      <div
        className={styles.lineChart}
        style={{ position: "relative", width: "100%" }}
        onMouseLeave={onMouseLeave}
      >
        <Chart
          type="line"
          data={data}
          options={options}
          plugins={[verticalLinePlugin]}
        />
      </div>
    );
  }
);

export default ChartJSFilledLineChart;
