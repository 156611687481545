import { useEffect, useState, useRef } from "react";
import styles from "./css/settings.module.css";
import left from "./assets/icons/left.svg"
import ethereumLogo from "./assets/crypto/ETH.png"
import usdcLogo from "./assets/crypto/USDC.png"
import { useEnclaveApi, useEnclaveConnect } from "./context/EnclaveConnectProvider";
import { updateGasConfig } from "./EnclaveUtils/functions";
import isPWA from "../../utils/pwaUtils";

export default function Settings(props) {

    const { userData } = useEnclaveApi();
    const { setUserData } = useEnclaveConnect();

    const bg = useRef(null);
    const [loading, setLoading] = useState(false);

    console.log("userData: ", userData);

    const [mode, setMode] = useState(userData?.gasConfig?.mode === 'USDC' ? 'USDC' : 'NATIVE'); // 'native' or 'usdc'

    const paymentOptions = [
        { id: 'NATIVE', logo: ethereumLogo, text: 'Native gas payment' },
        { id: 'USDC', logo: usdcLogo, text: 'USDC gas payment' },
    ];

    useEffect(() => {
        bg.current.addEventListener("click", (e) => {
            if (e.target === bg.current) {
                props.setSettingsOpen(false)
            }
        })
    }, [])

    const handleModeChange = (newMode) => {
        setLoading(true);
        updateGasConfig(userData.username, newMode).then((res) => {
            setUserData(res.data);
            // Set user data in local storage
            localStorage.setItem("userData", JSON.stringify(res.data));
            setMode(newMode);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    };

    return (
        <main ref={bg} className={styles.main}>
            <div className={`${styles.popUp} ${isPWA() ? styles.popUpOverrides : ""}`}>
                <div className={styles.header}>
                    <img src="/logos/enclave.svg" />
                </div>
                <div className={styles.heading}>
                    <button
                        onClick={() => { props.setWalletView() }
                        }   >
                        <img src={left} alt="" />
                    </button>
                    <p>Settings</p>
                </div>


                <div className={styles.top}>
                <div className={styles.settingsContainer}>
                    <h4 style={{color: "#888"}}>Default Gas Token</h4>
                    <div className={styles.cardContainer}>
                        {paymentOptions.map((option) => (
                        <div
                            key={option.id}
                            className={`${styles.paymentCard} ${mode === option.id ? styles.selectedCard : ''}`}
                            onClick={() => handleModeChange(option.id)}
                        >
                            <img src={option.logo} alt={option.text} className={styles.logo} />
                            <p>{option.text}</p>
                        </div>
                        ))}
                    </div>
                    </div>
                </div>
            </div>
        </main >
    );
}