import React, { useState, useRef, useEffect } from "react";
import styles from "./css/swapDropdown.module.css"; // Import your CSS module
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Search } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Dropdown = (props) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const dropdownRef = useRef(null);
	const [filteredTokens, setFilteredTokens] = useState(props.tokenList);

	const balanceTokens = Object.keys(props.balances);

	// Sort tokens by balance
	const sortedTokens = filteredTokens.sort((a, b) => {
		const balanceA = balanceTokens.includes(a.symbol) ? props.balances[a.symbol].total : 0;
		const balanceB = balanceTokens.includes(b.symbol) ? props.balances[b.symbol].total : 0;
		return balanceB - balanceA;
	});

	const toggleTokenDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const selectToken = (token) => {
		props.setSelectedToken(token);
		setIsDropdownOpen(false); // Close dropdown after selection
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsDropdownOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [filteredTokens]);

	useEffect(() => {
		setFilteredTokens(
			props.tokenList.filter((token) => {
				const match =
					token.symbol.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
					token.name?.toLowerCase().startsWith(searchTerm.toLowerCase());
				return match;
			}),
		);
	}, [searchTerm, props.tokenList]);

	return (
		<>
		<div className={styles.container}>
			{/* Token Dropdown */}
			<div className={styles.dropdown} onClick={toggleTokenDropdown}>
				<div className={styles.tokenLogo2box}>
					{(props.selectedToken.logoURI ?? props.selectedToken.icon) && (
						<img
							className={styles.tokenLogo}
							src={props.selectedToken.logoURI ?? props.selectedToken.icon}
							alt={props.selectedToken.symbol}
						/>
					)}
				</div>
				<div>{props.selectedToken.symbol}</div>
				<div className={styles.dropdownArrowBox}>
					<KeyboardArrowDownIcon />
				</div>
			</div>
		</div>
		{isDropdownOpen && (
			<div className={styles.main}>
				<div className={styles.dropdownMenu} ref={dropdownRef}>
					<h2>
						<span onClick={() => setIsDropdownOpen(false)}><ArrowBackIcon /></span>
						Select a token
					</h2>
					<input
						type="text"
						placeholder="Search..."
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
					<ul className={styles.options}>
						{
							sortedTokens.length == 0 &&
							<div className={styles.emptyList}>
								<Search />
								<br />
								No tokens found
							</div>
						}
						{sortedTokens.map((token) => {
							return (
								<li key={token.address} className={styles.dropdownItem} onClick={() => selectToken(token)}>
									<div className={styles.tokenDetails}>
										{(token.logoURI ?? token.icon) && <img src={token.logoURI ?? token.icon} alt={token.symbol} />}
										<div>
											<span>{token.name ?? token.symbol}</span><br />
											<span style={{fontSize: "0.8rem", opacity: 0.7}}>{token.symbol}</span>
										</div>
									</div>

									<div>
										{props.tokenYieldMap[token.symbol] && (
											<span>{(props.tokenYieldMap[token.symbol] * 100).toFixed(2)} %</span>
										)}
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		)}
		</>
	);
};

export default Dropdown;
