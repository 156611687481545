import { useEffect, useState, useRef } from "react";
import styles from "./css/qr.module.css";
import left from "./assets/icons/left.svg"
import link from "./assets/icons/link.svg"
import copy from "./assets/icons/copy.svg"
import { getAddressExplorer, networkDetails } from "./EnclaveUtils/constants";
import QRCode from "react-qr-code";
import isPWA from "../../utils/pwaUtils";

export default function QR(props) {

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCopied(false)
        }, 1000)

        return () => clearTimeout(timeout);
    }, [copied])


    const bg = useRef(null)

    useEffect(() => {
        bg.current.addEventListener("click", (e) => {
            if (e.target === bg.current) {
                props.setQrPopOpen(false)
            }
        })
    }, [])

    return (
        <main ref={bg} className={styles.main}>
            <div className={`${styles.popUp} ${isPWA() ? styles.popUpOverrides : ""}`}>
                <div className={styles.header}>
                    <img className={styles.logo}
                        src="/logos/enclave.svg" alt="Transfer" />
                </div>
                <div className={styles.heading}>
                    <button
                        onClick={() => { props.setWalletView() }
                        }   >
                        <img src={left} alt="" />
                    </button>
                    <p>Deposit</p>
                </div>


                <div className={styles.top}>
                    <QRCode
                        size={128}
                        style={{ height: "auto", maxWidth: "40%", width: "40%", borderRadius: 8, border: "5px solid #fff" }}
                        value={window.enclave.address}
                        viewBox={`10 10 128 128`}
                    />
                    <div onClick={() => { navigator.clipboard.writeText(props.userData.wallet?.scw_address); setCopied(true); }} className={styles.qrClipboardDiv}>
                        {copied ?
                            <p>
                                {'Address Copied!'}
                            </p>
                            :
                            <p>
                                {props.userData.wallet?.scw_address.slice(0, 6) + "..." + props.userData.wallet?.scw_address.slice(-4)}
                            </p>
                        }
                        <img src={copy} alt="" />
                    </div>
                </div>

                <div className={styles.bottom}>
                    <h2>Networks</h2>
                    <div className={styles.networksCon}>
                        {
                            props.userData.wallet.multi_scw.map((details) => {
                                return (
                                    <Network
                                        img={networkDetails[details.network].image}
                                        name={networkDetails[details.network].name}
                                        address={details.address}
                                        network={details.network}
                                        userData={props.userData}
                                    />
                                )
                            })
                        }
                    </div>
                    <br />
                </div>
            </div>
        </main >
    );
}

export const Network = (props) => {
    return (
        <div className={styles.network} onClick={() => window.open(getAddressExplorer(props.network, props.address))}>
            <div className={styles.left}>
                <img src={props.img} alt="" />
                <div>
                    <p>{props.name}</p>
                    <h2>
                        {props.address.slice(0, 6) + "..." + props.address.slice(-4)} <img src={link} alt="" />
                    </h2>
                </div>
            </div>
            <div className={styles.right}>
            </div>
        </div>
    )
}