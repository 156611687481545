import { useEffect, useState } from "react";
import styles from "./common.module.css";
import usdcLogo from "../EnclaveSDK/assets/crypto/USDC.png";
import ethLogo from "../EnclaveSDK/assets/crypto/ETH.png";
import usdtLogo from "../EnclaveSDK/assets/crypto/USDT.png";
import daiLogo from "../EnclaveSDK/assets/crypto/dai.png";
import arbLogo from "../EnclaveSDK/assets/crypto/ARB.png";
import opLogo from "../EnclaveSDK/assets/crypto/OP.png";
import linkLogo from "../EnclaveSDK/assets/crypto/link.png";
import btcLogo from "../EnclaveSDK/assets/crypto/btc.png";
import avaxLogo from "../EnclaveSDK/assets/crypto/avalanche.png";
import CircularProgress from '@mui/material/CircularProgress';
import { processBalances } from "../../utils/functions";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import { enterYieldOpportunity, nativeTokens } from "../../utils/stakeKitFunctions/main";
import { networkDetailsByNameKey, networkNameToChainId } from "../../utils/constants";
import { enabledNetworks } from "../EnclaveSDK/EnclaveUtils/constants";
import isPWA from "../../utils/pwaUtils";
import Dropdown from "../EarnTokenDropdownNew";
import OppDropdown from "../EarnOppDropdown";
import { KeyboardArrowDown } from "@mui/icons-material";
import { transferERC20_CallData } from "../../utils/aaveFunction";
import { ethers } from "ethers";

export const tokenList = [
    {   
        id: "USDC",
        name: "USDC",
        logo: usdcLogo,
    },
    {
        id: "ETH",
        name: "ETH",
        logo: ethLogo
    },
	{
		id: "USDT",
		name: "USDT",
		logo: usdtLogo
	},
	{
		id: "DAI",
		name: "DAI",
		logo: daiLogo
	},
	{
		id: "LINK",
		name: "LINK",
		logo: linkLogo
	},
	{
		id: "WBTC",
		name: "WBTC",
		logo: btcLogo
	},
	{
		id: "ARB",
		name: "ARB",
		logo: arbLogo
	},
	{
		id: "OP",
		name: "OP",
		logo: opLogo
	},
	{
		id: "AVAX",
		name: "AVAX",
		logo: avaxLogo
	}
]

const enabledNetworksByName = ["arbitrum", "optimism", "base", "avalanche-c"];

const defaultToken = {
	symbol: "USDC",
	name: "USD Coin",
	decimals: 6,
	logoURI: usdcLogo
}

const filterOpportunities = (opportunities, token, enabledNetworks) => {
	return opportunities.filter(opportunity => {
		const tokenMatch = opportunity.token.symbol.toLowerCase() === token.symbol.toLowerCase();
		const networkMatch = enabledNetworks.includes(opportunity.token.network);
		const positiveApy = opportunity.apy > 0;
		return tokenMatch && networkMatch && opportunity.isAvailable && positiveApy;
	}).sort((a, b) => {
		return b.apy - a.apy;
	});
}

const filterAllTokenList = (yieldOpportunities, allTokensList) => {
	const filteredOpps = filterOpportunities2(yieldOpportunities, {}, enabledNetworksByName).map((op) => {
		return op.token.symbol;
	});
	const tokenSet = new Set(filteredOpps);
	return allTokensList.filter(token => tokenSet.has(token.symbol));
}

const filterOpportunities2 = (opportunities, token, enabledNetworks) => {
	return opportunities.filter(opportunity => {
		const networkMatch = enabledNetworks.includes(opportunity.token.network);
		const positiveApy = opportunity.apy > 0;
		return  networkMatch && opportunity.isAvailable && positiveApy;
	}).sort((a, b) => {
		return b.apy - a.apy;
	});
}

const getTokenToTopYieldMapping = (opportunities) => {
	const tokenApyMap = {};
	opportunities.forEach((opportunity) => {
		const networkMatch = enabledNetworksByName.includes(opportunity.token.network);

		if (networkMatch) {
			const tokenSymbol = opportunity.token.symbol;
			const apy = opportunity.apy || 0; // Use 0 as default if 'apy' is not present
		
			if (!(tokenSymbol in tokenApyMap) || apy > tokenApyMap[tokenSymbol]) {
				tokenApyMap[tokenSymbol] = apy;
			}
		}
	});
	return tokenApyMap;
}

const filterOpportunities3 = (opportunities, token, enabledNetworks) => {
	return opportunities.filter(opportunity => {
		const tokenMatch = opportunity.token.symbol.toLowerCase() === token.name.toLowerCase();
		const positiveApy = opportunity.apy > 0;
		return  tokenMatch && opportunity.isAvailable && positiveApy;
	}).sort((a, b) => {
		return b.apy - a.apy;
	}).map((opportunity) => opportunity.token.network);
}

export default function EarnPage(props) {

	const { balances, userData, yieldOpportunities, balancesLoading, yieldOppLoading, tokenList, tokenListLoading, allTokensList } = useEnclaveApi();

	const [amount, setAmount] = useState();
	const [viewAll, setViewAll] = useState(false);
	const [filteredOpportunities, setFilteredOpportunities] = useState([]);
	const [selectedOpportunity, setSelectedOpportunity] = useState(null);
	const processedBalances = processBalances(balances.filter((balance) => enabledNetworks.includes(balance.chainId)));
	// const sourceTokenList = allTokensList;
	const sourceTokenList = balancesLoading ? [] : filterAllTokenList(yieldOpportunities, allTokensList);
	const [selectedToken, setSelectedToken] = useState(defaultToken);
	const [stakeLoading, setStakeLoading] = useState(false);

	const tokenYieldMap = getTokenToTopYieldMapping(balancesLoading ? [] : yieldOpportunities);

	const networkId = networkDetailsByNameKey[selectedOpportunity?.token.network]?.id;
	const selectedTokenBalance = processedBalances?.[selectedToken?.symbol]?.networks[
		networkId
	]?.amount ?? 0;

	const criticalDataLoading = yieldOppLoading || tokenListLoading || balancesLoading;


	useEffect(() => {
		if (yieldOpportunities.length > 0) {
			const tempFilteredOpportunities = filterOpportunities(yieldOpportunities, selectedToken, enabledNetworksByName);
			setFilteredOpportunities(tempFilteredOpportunities);

			if (!selectedOpportunity) {
				setSelectedOpportunity(tempFilteredOpportunities?.[0]);
			} else {
				if (selectedOpportunity?.token?.symbol != selectedToken.symbol) {
					setSelectedOpportunity(tempFilteredOpportunities?.[0]);
				}
			}
		}
	}, [selectedToken, yieldOpportunities, balances]);

	useEffect(() => {
		if (!selectedToken) {
			const sourceTokenList = Object.values(processedBalances);
			setSelectedToken(sourceTokenList?.[0]);
		}
	}, [balances])

	console.log("selectedOpportunity: ", selectedOpportunity);

	const stake = () => {
		setStakeLoading(true);
		const x = {
			integrationId: selectedOpportunity.id,
			addresses: {
				address: userData?.wallet?.scw_address 
			},
			args: {
				amount: (parseFloat(amount.toString()) * 0.995).toFixed(selectedToken.decimals ?? 18).toString(),
			}
		}
		enterYieldOpportunity(x).then((res) => {
			console.log("StakeKit Response: ", res);
			const feeAmount = ethers.parseUnits((parseFloat(amount.toString()) * 0.005).toFixed(selectedToken.decimals ?? 18).toString(), selectedToken.decimals ?? 18);
			let feeTxn;
			if (nativeTokens.includes(selectedToken.symbol)) {
				feeTxn = {
					label: "Fees",
					calldata: "0x",
					targetContractAddress: process.env.REACT_APP_FEE_ADDRESS,
					value: feeAmount,
					chainId: networkDetailsByNameKey[selectedOpportunity.token.network].id,
					walletAddress: window.enclave.address,
				}
			} else {
				const transferCallData = transferERC20_CallData(
					process.env.REACT_APP_FEE_ADDRESS,
					res[0].targetContractAddress,
					networkDetailsByNameKey[selectedOpportunity.token.network].id,
					feeAmount
				);

				feeTxn = {
					label: "Fees ",
					calldata: transferCallData,
					targetContractAddress: res[0].targetContractAddress,
					chainId: networkDetailsByNameKey[selectedOpportunity.token.network].id,
					walletAddress: window.enclave.address,
				}
				console.log("feeTxn: ", feeTxn);
			}
			window.enclave.initiateTransaction([
				feeTxn,
				...res
			], "Stake " + selectedToken.name + " on " + selectedOpportunity?.metadata?.name, res[0].targetContractAddress, feeAmount);
		}).catch((err) => {
			console.error("Error entering yield opportunity: ", err);
		}).finally(() => {
			setStakeLoading(false);
		});
	}

	return (
		<>
			<div className={`${styles.page} ${isPWA() ? styles.pageOverrides : ""}`}>
				<div className={styles.wrapper}>
					<h1>
						Earn
					</h1>
					<div className={styles.swapCardContainer}>
						<div className={styles.swapCard}>
							
							{
								criticalDataLoading && 
								<div style={{ padding: 10}}>
									<CircularProgress color="inherit" />
								</div>
							}
							{ selectedOpportunity && selectedOpportunity?.apy && !criticalDataLoading && (
								<>
									<div className={styles.apyDisplay}>
										<h2>Current APY</h2>
										<p className={styles.bigAPY}>{(selectedOpportunity?.apy * 100).toFixed(2) || 0}%</p>
									</div>
									{
										// !viewAll ?
										<div className={styles.supplyTxnOvw}>
											<div className={styles.overviewContainer}>
												<h6>OVERVIEW</h6>
											</div>
											<br />
													<div
														className={`${styles.supplyTxnOvwCard} ${styles.selectedCard}`}
														style={{ cursor: 'pointer' }}
														onClick={() => setViewAll(true)}
													>
														{
														networkDetailsByNameKey[selectedOpportunity.token.network].logo && 
														<img width={36} height={36} src={selectedOpportunity?.metadata?.provider?.logoURI ?? networkDetailsByNameKey[selectedOpportunity.token.network].logo} 
														onError={(e) => {
															e.target.onerror = null; // prevents looping
															e.target.src = networkDetailsByNameKey[selectedOpportunity.token.network].logo;
														  }} />}
														<div style={{ flex: 1 }}>
															<h3 style={{marginBottom: "4px"}}>{selectedOpportunity?.metadata?.name}</h3>
															<div className="tag">
																<div className="dot" />
																&nbsp; {networkDetailsByNameKey[selectedOpportunity.token.network].name}
															</div>
															{selectedOpportunity?.apy === filteredOpportunities[0]?.apy && (
																	<div className="tag green" style={{marginLeft: "4px"}}>HIGHEST RATE</div>
															)}
														</div>
														<div style={{ textAlign: "right", display: "flex" }}>
															<div className={styles.dropdownArrowBox}>
																<KeyboardArrowDown />
															</div>
														</div>
													</div>
										</div>
									}
								</>
							)}			

							{
								<>
											<div className={styles.swapInputWrapper}>
												<label>Amount</label>
												<div style={{ display: "flex", alignItems: "center", gap: 8 }}>
													<input
														className={styles.swapInput}
														type="text"
														inputMode="numeric"
														placeholder="0"
														value={amount}
														onChange={(e) => {
															let value = e.target.value;
															value = value.replace(/,/g, '.');
															if (value.startsWith("00")) {
																value = value.slice(value.indexOf("0") + 1);
															} else if (value.startsWith("-")) {
																value = "0";
															}
															if (isNaN(value)) {
																value = "";
															}
															setAmount(value);
														}}
													/>
													{
														selectedToken && 
														<Dropdown
														selectedToken={selectedToken}
														setSelectedToken={setSelectedToken}
														tokenList={sourceTokenList}
														balances={processedBalances}
														tokenYieldMap={tokenYieldMap}
														/>
													}
												</div>
												<div style={{ marginTop: 16, display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
													<div>
														<small>
															Balance:&nbsp;
															{
																selectedTokenBalance
															}
														</small>
													</div>
													<div>
														&nbsp;
														<button
															className="btn-ghost sm"
															onClick={() => {
																selectedTokenBalance ? setAmount(selectedTokenBalance) : setAmount(0);
															}}
														>
															MAX
														</button>
													</div>
												</div>
											</div>
											<button
											className="btn-primary w-full"
											disabled={criticalDataLoading || isNaN(parseFloat(amount)) || selectedTokenBalance < (amount ?? 0) || parseFloat(amount ?? 0) === 0 || !userData.wallet?.scw_address}
											onClick={async () => {
												stake();
											}}
											>
												{
													!userData?.wallet?.scw_address ? "Sign in to stake" :
													criticalDataLoading || stakeLoading ? <CircularProgress size={24} color="inherit" /> : 
													parseFloat(amount) > 0 ? 
													selectedTokenBalance >= parseFloat(amount) ? "Stake" : "Insufficient balance" :
													"Enter amount"
												}
											</button>
								</>
							}	
						</div>	
				</div>
				</div>
			</div>
			{viewAll && <OppDropdown setIsDropdownOpen={setViewAll} setSelectedOpp={setSelectedOpportunity} opportunities={filteredOpportunities} /> }
		</>
			
	)
}