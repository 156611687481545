import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

async function makeStripeOnrampCall(walletAddress) {
  try {
    const response = await axios.post(BASE_URL + '/stripe/onramp', 
      { walletAddress },
      {
        headers: {
          "Content-Type": 'application/json',
          'Authorization': process.env.REACT_APP_ENCLAVE_SDK_KEY
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error making Stripe onramp call:', error);
    throw error;
  }
}

// ... existing code ...

export { makeStripeOnrampCall };
