import React, { useEffect, useRef, useState } from "react";
import styles from "../css/Dropdown2.module.css"; // Import your CSS module


const Dropdown = (props) => {
    const [isTokenDropdownOpen, setIsTokenDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef(null);

    const [filteredTokens, setFilteredTokens] = useState(props.tokenList);

    const toggleTokenDropdown = () => {
        setIsTokenDropdownOpen(!isTokenDropdownOpen);
    };

    const selectToken = (token) => {
        props.setSelectedToken(token);
        setIsTokenDropdownOpen(false); // Close dropdown after selection
    };

    const balanceTokens = Object.keys(props.balances);

  // Sort tokens by balance
  const sortedTokens = filteredTokens.sort((a, b) => {
    const balanceA = balanceTokens.includes(a.address) ? props.balances[a.address].total : 0;
    const balanceB = balanceTokens.includes(b.address) ? props.balances[b.address].total : 0;
    return balanceB - balanceA;
});


useEffect(() => {
    setFilteredTokens(
        props.tokenList.filter((token) => {
            const match = 
            token.symbol.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
            token.name?.toLowerCase().startsWith(searchTerm.toLowerCase());
            return match;
        })
    );
}, [searchTerm, props.tokenList]);

    return (
        <div className={styles.container}>
            {/* Token Dropdown */}
            <div className={styles.dropdown} onClick={toggleTokenDropdown}>
                <div className={styles.tokenLogo2box}>
                    {
                        (props.selectedToken.logoURI ?? props.selectedToken.icon) &&
                        <img className={styles.tokenLogo} src={props.selectedToken.logoURI ?? props.selectedToken.icon} alt={props.selectedToken.symbol} />
                    }
                </div>
                <span>{props.selectedToken.symbol}</span>
                <div className={styles.dropdownArrowBox}>
                    <img className={styles.dropdownArrow} src="/icons/downArrow.svg" alt="Arrow Down" />
                </div>
            </div>
            {isTokenDropdownOpen && (
                <div className={styles.dropdownMenu} ref={dropdownRef}>
                <li>
                    <input 
                        type="text" 
                        placeholder="Search ..." 
                        value={searchTerm} 
                        onChange={e => setSearchTerm(e.target.value)} 
                    />
                </li>
                <ul className={styles.options}>
                    {sortedTokens.map((token) => {
                        return (
                        <li
                            key={token.address}
                            className={styles.dropdownItem}
                            onClick={() => selectToken(token)}
                        >
                            <div className={styles.tokenDetails}>
                                {
                                    (token.logoURI ?? token.icon) &&
                                    <img src={token.logoURI ?? token.icon} alt={token.symbol} />
                                }
                                <span>{token.name ?? token.symbol}</span>
                            </div>
                                
                            <div>
                                {
                                    props.balances[token.address] &&
                                    <span>{
                                        parseInt(props.balances[token.address].total * 100000) / 100000
                                    }</span>
                                }
                            </div>
                        </li>
                    )})}
                </ul>
            </div>
            )}
        </div>
    );
};

export default Dropdown;
