const API_KEY = "72a5b4b0-e727-48be-8aa1-5da9d62fe635"; // SOCKET PUBLIC API KEY
const headers = {
  "API-KEY": API_KEY,
  Accept: "application/json",
  "Content-Type": "application/json",
}
// // Makes a GET request to Bungee APIs for quote
async function getQuote(
  fromChainId,
  fromTokenAddress,
  toChainId,
  toTokenAddress,
  fromAmount,
  userAddress,
  recipient,
  uniqueRoutesPerBridge,
  sort,
  singleTxOnly
) {
  const response = await fetch(
    `https://api.socket.tech/v2/quote?fromChainId=${fromChainId}&fromTokenAddress=${fromTokenAddress}&toChainId=${toChainId}&toTokenAddress=${toTokenAddress}&fromAmount=${fromAmount}&userAddress=${userAddress}&uniqueRoutesPerBridge=${uniqueRoutesPerBridge}&sort=${sort}&singleTxOnly=${singleTxOnly}&recipient=${recipient}`,
    {
      method: "GET",
      headers: headers,
    }
  );

  const json = await response.json();
  return json;
}

// Makes a POST request to Bungee APIs for swap/bridge transaction data
async function getRouteTransactionData(route) {
  const response = await fetch("https://api.socket.tech/v2/build-tx", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ route: route }),
  });

  const json = await response.json();
  return json;
}

async function getTokensForNetwork(chainId) {
  console.log('API_KEY', API_KEY)
  const response = await fetch(`https://api.socket.tech/v2/token-lists/chain?chainId=${chainId}`, {
    method: "GET",
    headers: headers
  });
  
  const json = await response.json();
  return json;
};

 // Bridging Params fetched from users
//  const fromChainId = 137;
//  const toChainId = 56;
//  const fromAssetAddress = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174";
//  const toAssetAddress = "0x55d398326f99059fF775485246999027B3197955";
//  const amount = 100000000; // 100 USDC, USDC is 6 decimals
//  const userAddress = "0x3e8cB4bd04d81498aB4b94a392c334F5328b237b";

// Fetch tokens for Arbitrum (42161) & OP (10)
// const arbTokens = await getTokensForNetwork("42161");
// const opTokens = await getTokensForNetwork("10");

// Main function
export async function getCallData(
  fromChainId,
  fromAssetAddress,
  toChainId,
  toAssetAddress,
  amount,
  userAddress,
  recipient
) {
    const uniqueRoutesPerBridge = true; // Returns the best route for a given DEX / bridge combination
    const sort = "output"; // "output" | "gas" | "time"
    const singleTxOnly = true;
  
    // Quote for bridging 100 USDC on Polygon to USDT on BSC
    // For single transaction bridging, mark singleTxOnly flag as true in query params
    const quote = await getQuote(
      fromChainId,
      fromAssetAddress,
      toChainId,
      toAssetAddress,
      amount,
      userAddress,
      recipient,
      uniqueRoutesPerBridge,
      sort,
      singleTxOnly
    );
  
    // // Choosing first route from the returned route results
    const route = quote.result.routes[0];
  
    // // Fetching transaction data for swap/bridge tx
    const apiReturnData = await getRouteTransactionData(route);

    

    console.log('route', route);
    console.log('apiReturnData', apiReturnData);

    return {
      route, 
      apiReturnData
    }
  }


// TOKEN LIST FUNCTIONS
export const getFromTokenList = async (network) => {
  const resp = await fetch(`https://api.socket.tech/v2/token-lists/from-token-list?fromChainId=${network}`, {
      method: 'GET',
      headers: headers
  });

  return await resp.json();
}

export const getToTokenList = async (network1, network2) => {
  const resp = await fetch(`https://api.socket.tech/v2/token-lists/to-token-list?fromChainId=${network1}&toChainId=${network2}`, {
      method: 'GET',
      headers: headers
  });

  return await resp.json();
}

export const getCompleteTokenList = async () => {
  const resp = await fetch(`https://api.socket.tech/v2/token-lists/all`, {
      method: 'GET',
      headers: headers
  });

  return await resp.json();
}

export const getTokenListByChain = async (chainId) => {
  const resp = await fetch(`https://api.socket.tech/v2/token-lists/chain?chainId=${chainId}`, {
      method: 'GET',
      headers: headers
  });

  return await resp.json();
}

// BALANCE FUNCTIONS
export const getBalancesByTokenAndChain = async (walletAddress, tokenAddress, network) => {
  const resp = await fetch(`https://api.socket.tech/v2/balances/token-balance?tokenAddress=${tokenAddress}&chainId=${network}&userAddress=${walletAddress}`, {
      method: 'GET',
      headers: headers
  });

  return await resp.json();
}

export const getAllBalances = async (walletAddress) => {
  const resp = await fetch(`https://api.socket.tech/v2/balances?userAddress=${walletAddress}`, {
      method: 'GET',
      headers: headers
  });

  return await resp.json();
}
  
// main();  