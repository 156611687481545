"use client"
import { useEffect, useRef } from "react";
import styles from "./css/assetsDetails.module.css";
import left from "./assets/icons/left.svg"
import { networkDetails } from "./EnclaveUtils/constants";
import DonutChart from "./Chart/Chart";

export default function AssetsDetails(props) {
    const bg = useRef(null)

    useEffect(() => {
        bg.current.addEventListener("click", (e) => {
            if (e.target === bg.current) {
                props.setAssetsDetailsOpen(false)
            }
        })
    }, [])

    return (
        <main ref={bg} className={styles.main}>
            <div className={styles.popUp}>
                <div className={styles.header}>
                    <img className={styles.logo}
                        src="/logos/enclave.svg" alt="Transfer" />
                </div>
                <div className={styles.heading}>
                    <button
                        onClick={() => props.setWalletView()} >
                        <img src={left} alt="" />
                    </button>
                    <div>
                        <img src={props.selectedAsset.logoURI} alt="" />
                        <p>{props.selectedAsset.symbol}</p>
                    </div>
                </div>
                <br/>
                <div style={{textAlign: 'center'}}>
                    <h3 style={{color: 'lightgray'}}>Balance</h3>
                    <h2>{props.selectedAsset.total.toFixed(4)}</h2>
                </div>
                <br />
                <div className={styles.chartContainer}>
                    <div className={styles.chartbox}>
                        <DonutChart name={props.selectedAsset.name ?? props.selectedAsset.symbol} networkBalances={props.selectedAsset.networks} />
                    </div>
                </div>
                <div className={styles.top}>
                </div>
                <div className={styles.bottom}>
                    <h2>Networks</h2>
                    <div className={styles.networksCon}>
                        {
                            Object.keys(props.selectedAsset.networks).map((key) => {
                                console.log("Network details: ")
                                return (
                                    <Network
                                        img={networkDetails[key].image}
                                        name={networkDetails[key].name}
                                        address={props.selectedAsset.networks[key].address}
                                        amount={props.selectedAsset.networks[key].amount}
                                        value={props.selectedAsset.networks[key].amount}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </main >
    );
}

export const Network = (props) => {
    return (
        <div className={styles.network}>
            <div className={styles.left}>
                <img src={props.img} alt="" />
                <div>
                    <p>{props.name}</p>
                </div>
            </div>
            <div className={styles.right}>
                <h4>{props.amount}</h4>
            </div>
        </div>
    )
}
