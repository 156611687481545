import React from "react";
import styles from "./css/numpad.module.css";

const Numpad = ({
  onNumberClick,
  onBackspaceClick,
  onPresetClick,
  tokenSymbol,
}) => {
  const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "0"];
  const presets = ["10", "25", "50", "100"];

  const handleButtonPress = (value, callback) => {
    // Vibrate for 50ms on button press if supported
    if (navigator.vibrate) {
      navigator.vibrate(1);
    }
    callback(value);
  };

  return (
    <div className={styles.numpadContainer}>
      <div className={styles.presets}>
        {presets.map((preset) => (
          <button
            key={preset}
            onClick={() => handleButtonPress(preset, onPresetClick)}
            className={styles.presetButton}
          >
            {preset}
            {"%"}
          </button>
        ))}
      </div>
      <div className={styles.numpad}>
        {numbers.map((number) => (
          <button
            key={number}
            onClick={() => handleButtonPress(number, onNumberClick)}
            className={styles.numpadButton}
          >
            {number}
          </button>
        ))}
        <button
          onClick={() => handleButtonPress(null, onBackspaceClick)}
          className={styles.numpadButton}
        >
          &#x2190;
        </button>
      </div>
    </div>
  );
};

export default Numpad;
