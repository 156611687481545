import AAVEPOOLABI from "../abis/Aave.json";
import ERC20ABI from "../abis/ERC20.json";
import { ethers } from "ethers";
import { tokens, USDC_CONTRACT_ADDRESS } from "./constants";
import { RPC_URLS } from "../components/EnclaveSDK/EnclaveUtils/rpcUrls";


// Arb Sepolia Addresses
const AAVE_POOL = "0xBfC91D59fdAA134A4ED45f7B584cAf96D7792Eff";
const USDC = "0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d";

export const AAVE_POOL_MAP = {
    10: "0x794a61358D6845594F94dc1DB02A252b5b4814aD",
    421614: "0xBfC91D59fdAA134A4ED45f7B584cAf96D7792Eff",
    42161: "0x794a61358D6845594F94dc1DB02A252b5b4814aD",
    11155420: "0xb50201558B00496A145fE76f7424749556E326D8"
}

export const aUSDC_MAP = {
    42161: "0x625E7708f30cA75bfd92586e17077590C60eb4cD",
    10: "0x625E7708f30cA75bfd92586e17077590C60eb4cD"
}

const provider = new ethers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
provider.polling = false;
provider.pollingInterval = 10000;

export const getInterestRate = async (network) => {
    const provider = new ethers.JsonRpcProvider(RPC_URLS[network]);
    const aavePool = new ethers.Contract(AAVE_POOL_MAP[network], AAVEPOOLABI, provider);
    const reserveData = await aavePool.getReserveData(tokens.USDCe[network]);

    const oneE27 = 1000000000000000000000000000n;

    // Big int calculation
    const rate = reserveData[2] * 10000n / oneE27;

    return parseFloat(rate.toString()) / 100;
}

export const supplyUSDC_CallData = async (amount, wallet) => {
    const aavePool = new ethers.Contract(AAVE_POOL, AAVEPOOLABI, provider);

    const data = aavePool.interface.encodeFunctionData("deposit", [USDC, amount, wallet, 0]);
    return data;
}

export const approveUSDC_CallData = async () => {
    const usdc = new ethers.Contract(USDC_CONTRACT_ADDRESS, ERC20ABI, provider);

    // ABI encode approve function in ERC20 contract
    const encodedData = usdc.interface.encodeFunctionData("approve", [AAVE_POOL, ethers.MaxUint256]);
    return encodedData;
}

// Cross chain main net
export const supplyUSDCMulti_CallData = (amount, wallet, network, token) => {
    const provider = new ethers.JsonRpcProvider(RPC_URLS[network]);
    const aavePool = new ethers.Contract(AAVE_POOL_MAP[network], AAVEPOOLABI, provider);

    const data = aavePool.interface.encodeFunctionData("deposit", [token, amount, wallet, 0]);
    return data;
}

export const approveAddressERC20_CallData = (address, tokenAddress, network, amount) => {
    const provider = new ethers.JsonRpcProvider(RPC_URLS[network]);
    const usdc = new ethers.Contract(tokenAddress, ERC20ABI, provider);

    // ABI encode approve function in ERC20 contract
    const encodedData = usdc.interface.encodeFunctionData("approve", [address, amount]);
    return encodedData;
}

export const transferERC20_CallData = (address, tokenAddress, network, amount) => {
    const provider = new ethers.JsonRpcProvider(RPC_URLS[network]);
    const usdc = new ethers.Contract(tokenAddress, ERC20ABI, provider);

    // ABI encode approve function in ERC20 contract
    const encodedData = usdc.interface.encodeFunctionData("transfer", [address, amount]);
    return encodedData;
}