"use client";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./css/walletPopUp.module.css";
import disconnectIcon from "./assets/icons/disconnect.svg";

import {
  getMultiERC20Balance,
  getMultiEtherBalance,
} from "./EnclaveUtils/functions";
import {
  useEnclaveConnect,
  useEnclaveApi,
} from "./context/EnclaveConnectProvider";
import { fetchEthPrice } from "./EnclaveUtils/priceData";
import { processBalances } from "./EnclaveUtils/functions";
import { CircularProgress } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";

import { enabledNetworks } from "./EnclaveUtils/constants";
import isPWA from "../../utils/pwaUtils";
import {
  ArrowUpRight,
  ChevronLeft,
  ChevronRight,
  LogOut,
  Share2,
} from "lucide-react";
import { X } from "@mui/icons-material";

export default function WalletPopUp(props) {
  const [balance, setBalance] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [usdcBalance, setUsdcBalance] = useState(0);
  const [ethPrice, setEthPrice] = useState(2500);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const [processedBalances, setProcessedBalances] = useState({});

  const { disconnect } = useEnclaveConnect();
  const { balances } = useEnclaveApi();

  const bg = useRef(null);
  const networkDiv = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    // const fetchBalance = async () => {
    //     const localeth = window.localStorage.getItem("ethbalances");
    //     const localusdc = window.localStorage.getItem("usdcbalances");
    //     const lastFetched = window.localStorage.getItem("lastFetched");
    //     const localEthPrice = window.localStorage.getItem("ethPrice");
    //     if (localeth && lastFetched && (Date.now() - lastFetched < 1200000)) {
    //         setUsdcBalance(parseFloat(localusdc));
    //         setEthBalance(parseFloat(localeth));
    //         setEthPrice(parseFloat(localEthPrice));
    //     } else {
    //         const multiEthBalance = await getMultiEtherBalance(props.userData.wallet.multi_scw);
    //         const usdBalance = await getMultiERC20Balance(props.userData.wallet.multi_scw);
    //         const totalETH = multiEthBalance.reduce((acc, curr) => acc + parseFloat(curr.balance), 0);
    //         const totalUSDC = usdBalance.reduce((acc, curr) => acc + parseFloat(curr.balance), 0);
    //         const ethPrice = await fetchEthPrice();
    //         setEthPrice(parseFloat(ethPrice));
    //         setEthBalance(totalETH);
    //         setUsdcBalance(totalUSDC);
    //         window.localStorage.setItem("ethbalances", totalETH);
    //         window.localStorage.setItem("usdcbalances", totalUSDC);
    //         window.localStorage.setItem("lastFetched", Date.now());
    //         window.localStorage.setItem("ethPrice", ethPrice);
    //     }
    // }
    // const filteredBalances = balances.filter(balance => enabledNetworks.includes(balance.chainId));
    // setProcessedBalances(processBalances(filteredBalances));
    // setLoading(true);
    // fetchBalance().finally(() => {
    //     setLoading(false);
    // });
  }, []);

  useEffect(() => {
    bg.current.addEventListener("click", (e) => {
      if (e.target === bg.current) {
        props.setWalletOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    setBalance(ethBalance * ethPrice + usdcBalance);
  }, [ethBalance, usdcBalance, ethPrice]);
  return (
    <div ref={bg} className={styles.main}>
      <div
        className={`${styles.popUp} ${isPWA() ? styles.popUpOverrides : ""}`}
      >
        <div className={styles.header}>
          <div
            className={styles.backBtn}
            onClick={() => props.setWalletOpen(false)}
          >
            <ChevronLeft size={30} />
          </div>
          <h2>Profile</h2>
        </div>

        <div className={styles.profileCon}>
          <div className={styles.profileImg}>
            <img
              src={`https://api.dicebear.com/7.x/bottts-neutral/svg?seed=${props.userData.wallet?.scw_address}`}
              alt="Profile"
            />
          </div>
          <h2>{props.userData.username}</h2>
        </div>

        <div className={styles.menuOptions}>
          <div
            className={styles.menuItem}
            onClick={() => window.open("https://t.me/enclavemoney", "_blank")}
          >
            <div style={{ marginBottom: "4px" }} className={styles.menuIcon}>
              <TelegramIcon />
            </div>
            <span>Telegram</span>
            <div className={styles.arrowCircle}>
              <ArrowUpRight size={16} />
            </div>
          </div>
          <div
            className={styles.menuItem}
            onClick={() => window.open("https://x.com/tryendeavour", "_blank")}
          >
            <div style={{ marginBottom: "4px" }} className={styles.menuIcon}>
              <X />
            </div>
            <span>X</span>
            <div className={styles.arrowCircle}>
              <ArrowUpRight size={16} />
            </div>
          </div>
          <div
            className={styles.menuItem}
            onClick={() => {
              if (navigator.share) {
                navigator
                  .share({
                    title: "Check out endeavour.fun!",
                    url: "https://endeavour.fun",
                  })
                  .catch(console.error);
              } else {
                // Fallback for browsers that don't support Web Share API
                navigator.clipboard
                  .writeText("https://endeavour.fun")
                  .then(() => alert("Link copied to clipboard!"))
                  .catch(console.error);
              }
            }}
          >
            <div className={styles.menuIcon}>
              <Share2 />
            </div>
            <span>Share</span>
            <div className={styles.arrowCircle}>
              <ArrowUpRight size={16} />
            </div>
          </div>
          <div className={styles.menuItem} onClick={disconnect}>
            <div className={styles.menuIcon}>
              <LogOut />
            </div>
            <span>Log out</span>
            <div className={styles.arrowCircle}>
              <ArrowUpRight size={16} />
            </div>
          </div>
        </div>

        {/* <div className={styles.balanceCon}>
                    <p>Total Balance (USD)</p>
                    <h1>
                        {
                            loading ?
                            <CircularProgress size={20} color="inherit" /> :
                            <>
                            {
                                "$" + balance.toFixed(4)
                            }
                            </>
                        }
                        
                    </h1>
                </div> */}

        {/* <div className={styles.btnCon}>
          <button
            onClick={() => {
              props.setQrPopOpen(true);
              props.setWalletOpen(false);
              props.showTransfer(false);
            }}
          >
            <img src="/icons/down.svg" />
          </button>
          <button
            onMouseDown={() => {
              props.showTransfer(true);
              props.setQrPopOpen(false);
              props.setWalletOpen(false);
            }}
          >
            <img src="/icons/swap.svg" />
          </button>
          <button
            onClick={() => {
              props.setWalletOpen(false);
              props.setSettingsOpen(true);
            }}
          >
            <img src="/icons/setting.svg" />
          </button>
        </div> */}

        {/* <section className={styles.bottomCon}>
                    <div className={styles.searchCon}>
                        <h1>My Tokens</h1>
                        <div>
                            <img src="/icons/search.svg" />
                            <input type="text" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                    <div ref={networkDiv} className={styles.tokenCardCon}>
                        {
                            processedBalances && 
                            Object.keys(processedBalances).length > 0 && 
                            Object.keys(processedBalances).map((key) => {
                                return (
                                    <TokenCard
                                        key={processedBalances[key].symbol}
                                        assetDetails={processedBalances[key]}
                                        price={1}
                                        setSelectedAsset={props.setSelectedAsset}
                                        setWalletOpen={props.setWalletOpen}
                                        setAssetDetailsOpen={props.setAssetsDetailsOpen}
                                    />
                                )
                            })
                        }
                    </div>
                </section> */}
      </div>
    </div>
  );
}

const TokenCard = (props) => {
  return (
    <div
      className={styles.tokenCard}
      onClick={() => {
        props.setSelectedAsset(props.assetDetails);
        props.setAssetDetailsOpen(true);
        props.setWalletOpen(false);
      }}
    >
      <div className={styles.top}>
        <img src={props.assetDetails.logoURI} alt="" />
        <div>
          <h1>{props.assetDetails.symbol}</h1>
          {/* <p>${props.price}</p> */}
          <p>$ -</p>
        </div>
      </div>

      <div className={styles.bottom}>
        <h1>{props.assetDetails.total.toFixed(4)}</h1>
        {/* <p className={profit ? styles.profit : styles.loss}>
                    {profit ? "+<" : "-"}
                    ${profit ? props.gainLoss : props.gainLoss.substring(1)}
                </p> */}
      </div>
    </div>
  );
};
