import { ArrowBack } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./common.module.css";
import { Download } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import ethLogo from "../EnclaveSDK/assets/crypto/ETH.png";
import avaxLogo from "../EnclaveSDK/assets/crypto/avalanche.png";
import { networkDetails } from "../EnclaveSDK/EnclaveUtils/constants";
function DepositPage() {
  const navigate = useNavigate();

  const [processedBalances] = useState(null);

  const handleDepositClick = () => {
    navigate("/account");
  };

  const { balancesLoading } = useEnclaveApi();

  const BalanceRow = ({ symbol, token }) => {
    const { allTokensList } = useEnclaveApi();
    const logoURI =
      symbol === "ETH"
        ? ethLogo
        : symbol === "AVAX"
        ? avaxLogo
        : allTokensList.find(
            (tokenDetail) => tokenDetail.symbol === token.symbol
          )?.logoURI;
    const [viewAll, setViewAll] = useState();

    return (
      <>
        <tr
          key={symbol}
          className={styles.yieldRow}
          onClick={() => setViewAll(!viewAll)}
        >
          <td width={42}>
            <img width={40} height={40} src={logoURI} alt={token.name} />
          </td>
          <td>
            <h3>{token.name}</h3>
            <h5>
              {token.total.toFixed(4)} {symbol}
            </h5>
          </td>
          <td>
            {Object.keys(token.networks).map(
              (networkId) =>
                networkDetails[networkId] && (
                  <img
                    key={networkId}
                    src={networkDetails[networkId].image}
                    alt={networkDetails[networkId].name}
                    title={networkDetails[networkId].name}
                    className={styles.networkIcon}
                    width={24}
                    height={24}
                  />
                )
            )}
          </td>
        </tr>
        {viewAll &&
          Object.entries(token.networks).map(([id, details]) => {
            return (
              <tr key={id} className={styles.subYieldRow}>
                <td width={42} style={{ textAlign: "center" }}>
                  <img
                    src={networkDetails[parseInt(id)].image}
                    width={18}
                    alt="network-logo"
                  />
                </td>
                <td>
                  <p>{networkDetails[parseInt(id)].name}</p>
                </td>
                <td>{details.amount.toFixed(6)}</td>
              </tr>
            );
          })}
        {viewAll && (
          <tr key={"123"} className={styles.subYieldRow}>
            <td width={42}></td>
            <td></td>
            <td></td>
          </tr>
        )}
      </>
    );
  };

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <span style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <ArrowBack />
        </span>
        <h2>Deposit</h2>
      </div>

      {balancesLoading ? (
        <CircularProgress sx={{ color: "white" }} />
      ) : processedBalances && Object.keys(processedBalances).length > 0 ? (
        <div className={styles.balancesTableWrapper}>
          <table className={styles.balancesTable}>
            <tbody>
              {Object.entries(processedBalances || {}).map(
                ([symbol, token]) => (
                  <BalanceRow key={symbol} symbol={symbol} token={token} />
                )
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={styles.noBalances}>
          <Download sx={{ fontSize: 24 }} />
          <br />
          <h2>Welcome to Endeavour!</h2>
          <div>
            Let's get started by depositing some assets to your account. Click
            the button below to get started.
          </div>
          <br />
          <button onClick={handleDepositClick} className={"btn-primary"}>
            Deposit Assets
          </button>
        </div>
      )}
    </div>
  );
}

export default DepositPage;
