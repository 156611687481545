export const convertToNetworkTokenList = (chainId, allTokenList) => {
	let newTokenList = [];
	allTokenList.forEach((token) => {
		let chainDetails = token.chainIds.find((chain) => chain.chainId == chainId);
		if (chainDetails) {
			newTokenList.push({
				...token,
				chainId: chainDetails.chainId,
				address: chainDetails.address
			});
		}
	});
	return newTokenList;
}