import React, { useState, useRef, useEffect } from "react";
import styles from "./css/networkDropdown.module.css"; // Import your CSS module
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const NetworkDropdown = (props) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const selectNetwork = (network) => {
        props.setSelectedNetwork(network);
        setIsDropdownOpen(false); // Close dropdown after selection
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.container}>
            {/* Token Dropdown */}
            <div className={styles.dropdown} onClick={toggleDropdown}>
                <div className={styles.tokenLogo2box}>
                    {
                        (props.selectedNetwork.logo) &&
                        <img className={styles.tokenLogo} src={props.selectedNetwork.logo} alt={props.selectedNetwork.name} />
                    }
                </div>
                <span>{props.selectedNetwork.name}</span>
                <div className={styles.dropdownArrowBox}>
                    <KeyboardArrowDownIcon />
                </div>
            </div>
            {isDropdownOpen && (
                <ul className={styles.dropdownMenu} ref={dropdownRef}>
                    {props.networkList.map((network) => (
                        <li
                            key={network.id}
                            className={styles.dropdownItem}
                            onClick={() => selectNetwork(network)}
                        >
                            {
                                (network.logo) &&
                                <img src={network.logo} alt={network.name} />
                            }
                            <span>{network.name}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default NetworkDropdown;
