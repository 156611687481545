import "./css/nav-list.css";
import { Link, useLocation } from "react-router-dom";
import { NAV_OPTIONS } from "../utils/constants";

export const NavList = ({ onSelectEnd }) => {
	const location = useLocation();

	return (
		<ul className="nav-list">
			{NAV_OPTIONS.map((itm, i) => (
				<Link
					key={i}
					to={itm.pathname || ""}
					className={`nav-list-item ${location.pathname === itm.pathname ? "active" : ""}`}
					onClick={onSelectEnd}
				>
					{itm.icon}
					{itm.label}
				</Link>
			))}
		</ul>
	);
};
