import { useState } from "react";
import styles from "./common.module.css";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import { UserX, } from "lucide-react";
import { useLocation } from "react-router-dom";
import { CancelRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { exitYieldOpportunity } from "../../utils/stakeKitFunctions/main";
import isPWA from "../../utils/pwaUtils";

const Breadcrumbs = ({ items }) => {
	return (
		<nav className={styles.breadcrumbs}>
			{items.map((item, index) => (
				<span key={index}>
                    {
                        (item.path) ? 
                        <Link to={item.path}>{item.label}</Link> :
                        item.label
                    }
					{
                    index < items.length - 1 && 
                    ' > '
                    } {/* Separator */}
				</span>
			))}
		</nav>
	);
};

export default function YieldDetailsPage() {
	const { userData, balances, yieldBalances, balancesLoading, yieldOpportunities } = useEnclaveApi();
    const location = useLocation(); // Get the current location object

	// Extract query parameters
	const queryParams = new URLSearchParams(location.search);
	const integrationId = queryParams.get('integrationId');
	const tokenSymbol = queryParams.get('tokenSymbol');
    console.log(integrationId, tokenSymbol);
    const [amount, setAmount] = useState();
    const [withdrawLoading, setWithdrawLoading] = useState(false);

    const opportunityDetails = yieldBalances[tokenSymbol]?.opportunities[integrationId];

    const yieldAssetAmount = parseFloat(opportunityDetails?.primaryStakedAssetDetails.amount);

    console.log(opportunityDetails);

    const breadcrumbItems = [
		{ label: 'Dashboard', path: '/dashboard'},
		{ label: integrationId },
	];

	const withdraw = () => {
		setWithdrawLoading(true);
		const x = {
			integrationId: integrationId,
			addresses: {
				address: userData?.wallet?.scw_address 
			},
			args: {
				amount: amount,
			}
		}
		exitYieldOpportunity(x).then((res) => {
			console.log("StakeKit Response: ", res);
			window.enclave.initiateTransaction(res, "Withdraw " + tokenSymbol + " from " + opportunityDetails?.primaryStakedAssetDetails?.token?.name);
		}).catch((err) => {
			console.error("Error entering yield opportunity: ", err);
		}).finally(() => {
			setWithdrawLoading(false);
		});
	}

	return (
		<div className={`${styles.page} ${isPWA() ? styles.pageOverrides : ""}`} style={{ maxWidth: "960px", margin: "auto", padding: "24px" }}>
			{!userData || !userData.wallet?.scw_address ? 
				<div className={styles.disconnectedAccount}>
					<UserX size={32} />
					<p>Please connect your account to view details</p>
				</div> :
                balancesLoading ?
                <CircularProgress /> :
                !!integrationId && !!tokenSymbol && opportunityDetails ?
				<>
                    <Breadcrumbs items={breadcrumbItems} />
					<h1>Details</h1>
                    <div className={styles.yieldDetailsParentContainer}>
                        <br />
                        <div className={styles.yieldDetailsContainer}>
                            <div>
                                <img src={opportunityDetails.primaryStakedAssetDetails.token.logoURI} width={36} />
                                <div>
                                    <h3>{opportunityDetails.primaryStakedAssetDetails.token.name}</h3>
                                    <h5>{parseFloat(opportunityDetails.primaryStakedAssetDetails.amount).toFixed(7)} {opportunityDetails.primaryStakedAssetDetails.token.symbol}</h5> 
                                </div>
                            </div>
                            <div>
                                Earning
                                <h3>{(opportunityDetails.apy * 100).toFixed(4)} %</h3>
                            </div>
                        </div>
                        <br />
                        <div>
                            <br />
                            <h3>Withdraw</h3>
                            <br />
                            <div className={styles.swapInputWrapper}>
                                <label>Amount</label>
                                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                                    <input
                                        className={styles.swapInput}
                                        type="number"
                                        inputMode="numeric"
                                        placeholder="0"
                                        value={amount}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            console.log("VALUE: ", value);
                                            if (value.startsWith("00")) {
                                                value = value.slice(value.indexOf("0") + 1);
                                            } else if (value.startsWith("-")) {
                                                value = "0";
                                            }
                                            if (isNaN(value)) {
                                                value = "";
                                            }
                                            setAmount(value);
                                        }}
                                    />
                                    <img width="28px" height="28px" src={opportunityDetails.primaryStakedAssetDetails.token.logoURI} alt={tokenSymbol} />
                                    <p style={{ fontSize: 24 }}>
                                        {opportunityDetails.primaryStakedAssetDetails.token.symbol}
                                    </p>
                                </div>
                                <div style={{ marginTop: 16, display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                    <div>
                                        <small>
                                            Wallet balance:&nbsp;
                                            {
                                                yieldAssetAmount.toFixed(7)
                                            }
                                        </small>
                                    </div>
                                    <div>
                                        &nbsp;
                                        <button
                                            className="btn-ghost sm"
                                            onClick={() => {
                                                yieldAssetAmount.toFixed(7) ? 
                                                setAmount(yieldAssetAmount.toFixed(7)) : setAmount(0);
                                            }}
                                        >
                                            MAX
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <button
                            className="btn-primary w-full"
                            disabled={balancesLoading || yieldAssetAmount < parseFloat(amount) || parseFloat(amount) === 0 || !amount}
                            onClick={async () => {
                                withdraw();
                            }}
                        >
                            {
                                balancesLoading || withdrawLoading ? <CircularProgress size={24} color="inherit" /> : 
                                parseFloat(amount) > 0 ? 
                                yieldAssetAmount >= parseFloat(amount) ? "Withdraw" : "Insufficient balance" :
                                "Enter amount"
                            }
                        </button>
                    </div>
				</> :
                <div className={styles.noBalances}>
                    <CancelRounded size={48} />
                    <p>Invalid token or integration</p>
                    <br />
                </div>
			}	
		</div>
	);
}
