import { RPC_URLS } from './rpcUrls';

const { ethers } = require('ethers');

// ABI for the Chainlink price feed
const priceFeedAbi = [
  {
    "inputs": [],
    "name": "latestRoundData",
    "outputs": [
      { "internalType": "uint80", "name": "roundId", "type": "uint80" },
      { "internalType": "int256", "name": "answer", "type": "int256" },
      { "internalType": "uint256", "name": "startedAt", "type": "uint256" },
      { "internalType": "uint256", "name": "updatedAt", "type": "uint256" },
      { "internalType": "uint80", "name": "answeredInRound", "type": "uint80" }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];

// Create a contract instance

export async function fetchEthPrice() {
  // Arbitrum RPC URL (you can use any Arbitrum node provider)
  const provider = new ethers.JsonRpcProvider('https://arb1.arbitrum.io/rpc');
  // Chainlink ETH/USD price feed address on Arbitrum
  const priceFeedAddress = '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612';
  const priceFeed = new ethers.Contract(priceFeedAddress, priceFeedAbi, provider);
  const latestRoundData = await priceFeed.latestRoundData();
  const ethPrice = ethers.formatUnits(latestRoundData.answer, 8); // The price has 8 decimal places
  console.log(`ETH Price: $${ethPrice}`);
  return ethPrice;
}

export async function fetchAvaxPrice() {
  const provider = new ethers.JsonRpcProvider(RPC_URLS[43114]);
  const priceFeed = new ethers.Contract("0x0A77230d17318075983913bC2145DB16C7366156", priceFeedAbi, provider);
  const latestRoundData = await priceFeed.latestRoundData();
  const avaxPrice = ethers.formatUnits(latestRoundData.answer, 8); // The price has 8 decimal places
  console.log(`AVAX Price: $${avaxPrice}`);
  return avaxPrice;
}