"use client";
import { useEffect, useState } from "react";
import ConnectBtn from "./ConnectBtn";
import NameBox from "./NameBox";
import WalletPopUp from "./WalletPopUp";
import Transaction from "./Transaction";
import { setWindowEnclave } from "./EnclaveUtils/enclaveWindowFunctions";
import Transfer from "./Transfer";
import QR from "./QR";
import AssetsDetails from "./AssetsDetails";
import { useEnclaveApi } from "./context/EnclaveConnectProvider";
import { useEnclaveConnect } from "./context/EnclaveConnectProvider";
import Settings from "./Settings";

export default function Connect() {
  const { setUserData } = useEnclaveConnect();
  const { userData } = useEnclaveApi();

  const [userName, setUserName] = useState("");
  const [nameBoxOpen, setNameBoxOpen] = useState(false);
  const [walletOpen, setWalletOpen] = useState(false);
  const [qrPopOpen, setQrPopOpen] = useState(false);
  const [assetsDetailsOpen, setAssetsDetailsOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState({});
  const [connectionState, setConnectionState] = useState("disconnected");
  const [tranactionPopUp, setTransactionPopUp] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const setWalletView = () => {
    setWalletOpen(true);
    setQrPopOpen(false);
    setTransfer(false);
    setAssetsDetailsOpen(false);
    setSelectedAsset({});
    setSettingsOpen(false);
  };

  useEffect(() => {
    if (Object.keys(userData ?? {}).length > 0) {
      setWindowEnclave(userData);
      setConnectionState("connected");
    } else {
      setConnectionState("disconnected");
    }
  }, [userData]);

  useEffect(() => {
    window.addEventListener("openTransactionPopUp", () => {
      setTransactionPopUp(true);
      setTransfer(false);
    });
  }, []);

  return (
    <div>
      <ConnectBtn
        state={connectionState}
        userData={userData ?? {}}
        setState={setConnectionState}
        setNameBoxOpen={setNameBoxOpen}
        walletOpen={walletOpen}
        setWalletOpen={setWalletOpen}
      />
      {nameBoxOpen && (
        <NameBox
          setNameBox={setNameBoxOpen}
          setUserName={setUserName}
          setConnectionState={setConnectionState}
          setUserData={setUserData}
        />
      )}
      {walletOpen && Object.keys(userData ?? {}).length > 0 && (
        <WalletPopUp
          setAssetsDetailsOpen={setAssetsDetailsOpen}
          setSelectedAsset={setSelectedAsset}
          setQrPopOpen={setQrPopOpen}
          setWalletOpen={setWalletOpen}
          userData={userData}
          showTransfer={setTransfer}
          setSettingsOpen={setSettingsOpen}
        />
      )}

      {transfer && (
        <Transfer showTransfer={setTransfer} setWalletView={setWalletView} />
      )}
      {tranactionPopUp && (
        <Transaction
          senderAddress={userData.wallet.scw_address}
          chain={window.enclave.transactionDetails.chainId}
          amount="-148.2083019283"
          unKnown="3661"
          baseFee="96.02013980"
          baseFeeUSD="561.12390"
          priorityFee="52.02013980"
          priorityFeeUSD="561.12390"
          gasFee="0.02013980"
          gasFeeUSD="561.12390"
          network="43113"
          setTransactionPopUp={setTransactionPopUp}
          userData={userData}
        />
      )}

      {qrPopOpen && (
        <QR
          userData={userData}
          setQrPopOpen={setQrPopOpen}
          setWalletView={setWalletView}
        />
      )}

      {assetsDetailsOpen && (
        <AssetsDetails
          userData={userData}
          setAssetsDetailsOpen={setAssetsDetailsOpen}
          setWalletView={setWalletView}
          selectedAsset={selectedAsset}
        />
      )}

      {settingsOpen && (
        <Settings
          setSettingsOpen={setSettingsOpen}
          setWalletView={setWalletView}
        />
      )}
    </div>
  );
}
