import React, { useState } from "react";
import styles from "../css/Dropdown.module.css"; // Import your CSS module
import { ChevronDown } from "lucide-react";

const NetworkDropdown = (props) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const selectNetwork = (network) => {
		props.setSelectedNetwork(network);
		setIsDropdownOpen(false);
	};

	return (
		<div className={styles.container}>
			{/* Token Dropdown */}
			<div className={styles.dropdown} onClick={toggleDropdown}>
				<div className={styles.tokenLogo2box}>
					{props.selectedNetwork.logo && (
						<img className={styles.tokenLogo} src={props.selectedNetwork.logo} alt={props.selectedNetwork.name} />
					)}
				</div>
				<span>{props.selectedNetwork.name}</span>
				<ChevronDown size={20} />
			</div>
			{isDropdownOpen && (
				<ul className={styles.dropdownMenu}>
					{props.networkList.map((network) => (
						<li key={network.id} className={styles.dropdownItem} onClick={() => selectNetwork(network)}>
							{network.logo && <img src={network.logo} alt={network.name} />}
							<span>{network.name}</span>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default NetworkDropdown;
