import { ethers } from "ethers";
import { RPC_URLS } from "../components/EnclaveSDK/EnclaveUtils/rpcUrls";
import GasNFTABI from "../abis/GasNFT.json";

// export const GAS_NFT_ADDRESS = "0x859F6ac605D36EDDeF68459D3e16cc3BE7401ee5";
export const GAS_NFT_ADDRESS = "0xD4991Bec5C9AE87F202efA138A7460fbCCFfDC08";

const provider = new ethers.JsonRpcProvider(RPC_URLS[10]);

export const isMember = async (userAddress) => {
    const contract = new ethers.Contract(GAS_NFT_ADDRESS, GasNFTABI, provider);
    const count = await contract.balanceOf(userAddress);
    return count > 0;
}

export const getBuyCalldata = (userAddress) => {
    const contract = new ethers.Contract(GAS_NFT_ADDRESS, GasNFTABI, provider);
    const calldata = contract.interface.encodeFunctionData("mintNFT", [userAddress]);
    console.log("calldata", calldata);
    return calldata;
}