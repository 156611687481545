import React from "react";
import styles from "./common.module.css";
import { Apple, ArrowBack, Google, Payment } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { StripePopUp } from "./onRampPopUp/stripePopUp";
import { loadStripeOnramp } from "@stripe/crypto";
import { CircularProgress } from "@mui/material";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import { useState, useEffect } from "react";
import { makeStripeOnrampCall } from "../../utils/stripeFunction";

function BuyPage() {
  const navigate = useNavigate();

  const {
    userData,
    balances,
    yieldBalances,
    balancesLoading,
    yieldBalancesLoading,
    yieldOppLoading,
    portfolioValue,
    stripeOnRamp,
    setStripeOnRamp,
  } = useEnclaveApi();

  const [stripeOnRampLoading, setStripeOnRampLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  console.log("clientSecret", clientSecret);
  console.log("stripeOnRamp", stripeOnRamp);
  console.log("stripeOnRampLoading", stripeOnRampLoading);

  // UNCOMMENT AFTER ACTIVATING STRIPE LIVE
  useEffect(() => {
  	const stripeOnrampFunction = async () => {
  		const onramp = await loadStripeOnramp(process.env.REACT_APP_STRIPE_KEY);
  		const response = await makeStripeOnrampCall(userData.wallet.scw_address);
  		const stripeClientSecret = response.client_secret;
  		setStripeOnRamp(onramp);
  		setClientSecret(stripeClientSecret);
  	}
  	setStripeOnRampLoading(true);
  	stripeOnrampFunction().then((onramp) => {
  		console.log("stripe onramp", onramp);
  	}).catch((e) => {
  		console.log(e);
  		setStripeOnRamp(null);
  	}).finally(() => {
  		setStripeOnRampLoading(false);
  	});
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <span onClick={() => navigate("/")}>
          <ArrowBack />
        </span>
        <h2>Add Money</h2>
      </div>
      {/* <div className={styles.onRampComingSoon}>
        <div className={styles.iconContainer}>
          <Payment sx={{ fontSize: 20 }} />
          <Google sx={{ fontSize: 20 }} />
          <Apple sx={{ fontSize: 24 }} />
        </div>
        <h2>Coming Soon!</h2>
        <p>Buy crypto with debit/credit card, apple pay, or google pay.</p>
      </div> */}
      <br />
      {
        stripeOnRampLoading ?
        <CircularProgress color="inherit" /> :
        <StripePopUp clientSecret={clientSecret} />
      }
    </div>
  );
}

export default BuyPage;
