import styles from "../../EnclaveSDK/css/transfer.module.css";
import { useEnclaveApi } from "../../EnclaveSDK/context/EnclaveConnectProvider";
import { convertToNetworkTokenList } from "../../../utils/tokenListUtils";
import { processBalances2 } from "../../../utils/functions";
import { ethers } from "ethers";
import { useState, useEffect, useRef } from "react";
import { networkDetailsByNameKey as networkDetails } from "../../../utils/constants";
import isPWA from "../../../utils/pwaUtils";
import { CircularProgress } from "@mui/material";
import { BuySellModes } from "../tokenDetails";
import NetworkDropdown from "../../EnclaveSDK/Components/NetworkDropDowns";
import Dropdown from "../../SwapTokenDropdown";
import { getRelayQuoteAmountIn, getRelayQuote } from "../../../utils/relayFunctions";
import { approveAddressERC20_CallData } from "../../EnclaveSDK/EnclaveUtils/functions";

export const BuySellPopup = (props) => {

    const { allTokensList, balances, walletAddress } = useEnclaveApi();
    const { selectedToken, mode, setMode } = props;

    const selectedTokenChainIds = selectedToken.chainIds.map((chain) => chain.chainId);

    const networkList = Object.values(networkDetails);

    const selectedTokenNetworkList = networkList.filter((network) => {
        return selectedTokenChainIds.includes(network.id)
    });

    const [selectedTokenNetwork, setSelectedTokenNetwork] = useState(selectedTokenNetworkList[0]);
	const [counterNetwork, setCounterNetwork] = useState(networkList[0]);

    const selectedTokenAddress = selectedToken.chainIds.find((chain) => chain.chainId === selectedTokenNetwork.id).address;

	const counterTokenList = convertToNetworkTokenList(counterNetwork.id, allTokensList);

    const [counterNetworkBalances, setCounterNetworkBalances] = useState({});
    const [selectedTokenNetworkBalances, setSelectedTokenNetworkBalances] = useState({});

	const [counterToken, setCounterToken] = useState(counterTokenList?.[0]);

    const [selectedTokenAmount, setSelectedTokenAmount] = useState();
    const [counterTokenAmount, setCounterTokenAmount] = useState();

	const validSelectedTokenAmount = parseFloat(selectedTokenAmount) > 0;
    const validCounterTokenAmount = parseFloat(counterTokenAmount) > 0;

	const withinBalanceSell = parseFloat(selectedTokenAmount) <= parseFloat(selectedTokenNetworkBalances[selectedTokenAddress]?.total);
    const withinBalanceBuy = parseFloat(counterTokenAmount) <= parseFloat(counterNetworkBalances[counterToken.address]?.total);

	const validBuy = validCounterTokenAmount && withinBalanceBuy;
    const validSell = validSelectedTokenAmount && withinBalanceSell;

    const [swapResponse, setSwapResponse] = useState({});

    const [loading, setLoading] = useState(false);

    console.log("SELECTED TOKEN AMOUNT: ", selectedTokenAmount, validSelectedTokenAmount);
    console.log("SELECTED TOKEN: ", selectedToken.symbol);
    console.log("SELECTED TOKEN NETWORK: ", selectedTokenNetwork.id);
    console.log("SELECTED TOKEN ADDR: ", selectedTokenAddress);
    console.log("COUNTER TOKEN: ", counterToken.symbol);
    console.log("COUNTER TOKEN NETWORK: ", counterNetwork.id);
    console.log("COUNTER TOKEN ADDR: ", counterToken.address);

	const bg = useRef(null);

	useEffect(() => {
		bg.current.addEventListener("click", (e) => {
			if (e.target === bg.current) {
				setMode(BuySellModes.NONE);
			}
		});
	}, []);

	useEffect(() => {
		setCounterNetworkBalances(
			processBalances2(balances.filter((balance) => balance.chainId === counterNetwork.id)),
		);
        setSelectedTokenNetworkBalances(
            processBalances2(balances.filter((balance) => balance.chainId === selectedTokenNetwork.id)),
        )
	}, [balances, counterNetwork, selectedTokenNetwork]);

    useEffect(() => {
        if (mode === BuySellModes.BUY && validSelectedTokenAmount) {
            setLoading(true);
            getRelayQuoteAmountIn(
                ethers.parseUnits(selectedTokenAmount.toString(), selectedToken.decimals ?? 18), 
                counterNetwork.id, counterToken.address, selectedTokenNetwork.id, selectedTokenAddress, walletAddress
            ).then((result) => {
                setSwapResponse(result);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setLoading(false);
            })
        } else if (mode === BuySellModes.SELL && validSelectedTokenAmount) {
            setLoading(true);
            getRelayQuote(
                ethers.parseUnits(selectedTokenAmount.toString(), selectedToken.decimals ?? 18), 
                selectedTokenNetwork.id, selectedTokenAddress, counterNetwork.id, counterToken.address, walletAddress
            ).then((result) => {
                setSwapResponse(result);
            }).catch((e) => {
                console.error(e);
                setSwapResponse({});
            }).finally(() => {
                setLoading(false);
            })
        }
    }, [selectedTokenNetwork, counterToken, counterNetwork, selectedTokenAmount]);

    const handleSubmit = () => {
        setMode(BuySellModes.NONE);
        window.enclave.initiateTransaction(
            [
                {
                    label: "Approve",
                    calldata: swapResponse.steps[0].items[0].data.data,
                    targetContractAddress: swapResponse.steps[0].items[0].data.to,
                    chainId: swapResponse.steps[0].items[0].data.chainId,
                    walletAddress: window.enclave.address,
                    value: swapResponse.steps[0].items[0].data.value
                },
                {
                    label: "Crosschain Swap",
                    calldata: swapResponse.steps[1].items[0].data.data,
                    targetContractAddress: swapResponse.steps[1].items[0].data.to,
                    chainId: swapResponse.steps[1].items[0].data.chainId,
                    walletAddress: window.enclave.address,
                    value: swapResponse.steps[1].items[0].data.value
                },
            ],
            `${mode} ${selectedTokenAmount} ${selectedToken.symbol} for ${mode === BuySellModes.BUY ?
            swapResponse?.details?.currencyIn.amountFormatted.substr(0,6) :
            swapResponse?.details?.currencyOut.amountFormatted.substr(0,6)} ${counterToken.symbol}`,
        );
    }

    return (
        <div ref={bg} className={styles.main}>
			<div className={`${styles.popUp} ${isPWA() ? styles.popUpOverrides : ""}`}>
				<div className={styles.header}>
					<img
						onMouseDown={() => {
							setMode(BuySellModes.NONE);
						}}
						src="/icons/back.svg"
						alt="close"
					/>
					<div className={styles.headerTitle}>
						<h2>{mode}</h2>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", gap: "4px"}}>
                            <img src={selectedToken.logoURI} width={48} />
                            <h2>{selectedToken.symbol}</h2>
                        </div>
					</div>
				</div>

				{/*New SWAP UI */}
				<div style={{ display: "flex", flexDirection: "column", gap: 8, width: "100%" }}>
					<div className={styles.swapInputWrapper}>
						<div style={{ width: "fit-content" }}>
                            <div style={{paddingBottom: "10px", color: "gray"}}>Enter amount to {mode.toLowerCase()}</div>
							<NetworkDropdown
								networkList={selectedTokenNetworkList}
								selectedNetwork={selectedTokenNetwork}
								setSelectedNetwork={setSelectedTokenNetwork}
							/>
						</div>
						<div style={{ display: "flex" }}>
							<input
								className={styles.swapInput}
								value={selectedTokenAmount}
								type="number"
								inputMode="decimal"
								placeholder="0.00"
								onChange={(e) => {
									let value = e.target.value;
                                    value = value.replace(/,/g, '.');
                                    if (value.startsWith("00")) {
                                        value = value.slice(value.indexOf("0") + 1);
                                    } else if (value.startsWith("-")) {
                                        value = "0";
                                    }
                                    if (isNaN(value)) {
                                        value = "";
                                    }
									setSelectedTokenAmount(value);
								}}
							/>
								{/* {fromToken && (
                                    <Dropdown
                                        selectedToken={fromToken}
                                        setSelectedToken={setFromToken}
                                        tokenList={sourceTokenList}
                                        balances={sourceNetworkBalances}
                                    />
                                )} */}
						</div>
                        {
                            mode === BuySellModes.SELL &&
                            <small>
                                Balance:&nbsp;
                                {selectedToken && selectedTokenNetworkBalances[selectedTokenAddress]
                                    ? parseFloat(selectedTokenNetworkBalances[selectedTokenAddress]?.total)
                                    : 0}
                            </small>
                        }
					</div>
					<div className={styles.swapInputWrapper}>
                        <div style={{marginBottom: "10px", color: "gray"}}>
                            {
                                mode === BuySellModes.BUY ?
                                "Pay with" :
                                "Sell for"
                            }
                        </div>
						<div style={{ width: "fit-content" }}>
							<NetworkDropdown
								networkList={networkList}
								selectedNetwork={counterNetwork}
								setSelectedNetwork={setCounterNetwork}
							/>
						</div>
						<div style={{ display: "flex" }}>
							<input
								className={styles.swapInput}
								value={
                                    mode === BuySellModes.BUY ?
                                    swapResponse?.details?.currencyIn.amountFormatted :
                                    swapResponse?.details?.currencyOut.amountFormatted
                                }
								type="number"
								inputMode="decimal"
								placeholder="0.00"
                                disabled
								// onChange={(e) => {
								// 	let value = e.target.value;
								// 	console.log("VALUE: ", value);
								// 	if (value.startsWith("00")) {
								// 		value = value.slice(value.indexOf("0") + 1);
								// 	} else if (value.startsWith("-")) {
								// 		value = "0";
								// 	}
								// 	if (isNaN(value)) {
								// 		value = "";
								// 	}
								// 	setCounterTokenAmount(value);
								// }}
							/>
								{counterToken && (
								<Dropdown
									selectedToken={counterToken}
									setSelectedToken={setCounterToken}
									tokenList={counterTokenList}
									balances={counterNetworkBalances}
								/>
							)}
						</div>
						{
                            mode === BuySellModes.BUY &&
                            <small>
                                Balance:&nbsp;
                                {counterNetwork && counterNetworkBalances[counterToken.address]
                                    ? parseFloat(counterNetworkBalances[counterToken.address]?.total)
                                    : 0}
                            </small>
                        }
					</div>
					<button className="btn-primary w-full" disabled={(((mode == BuySellModes.BUY) && !validBuy) || (mode == BuySellModes.SELL) && !validSell)} onClick={handleSubmit}>
							{loading ? (
								<CircularProgress size={20} color="inherit" />
							) : (validBuy && validSell) ? (
								{mode}
							) : (!validSelectedTokenAmount) ? (
								"Enter amount"
							) : (((mode == BuySellModes.BUY) && !withinBalanceBuy) || (mode == BuySellModes.SELL) && !withinBalanceSell) ? (
								"Insufficient balance"
							) :
							"Unknown error"
							}
					</button>
				</div>
			</div>
		</div>
    )
}
