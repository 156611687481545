import axios from 'axios';
import { BASE_URL, API_KEY } from './base';

export const patchTransaction = async (transactionId) => {
  try {
    const response = await axios.patch(`${BASE_URL}/v1/transactions/${transactionId}`, {}, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        'X-API-KEY': API_KEY
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error patching transaction:', error);
    throw error;
  }
};