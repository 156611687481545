import { CircularProgress } from "@mui/material";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import {
  approveAddressERC20_CallData,
  transferERC20_CallData,
} from "../../utils/aaveFunction";
import { processBalances2 } from "../../utils/functions";
import styles from "./common.module.css";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import NetworkDropdown from "../SwapNetworkDropdown";
import Dropdown from "../SwapTokenDropdown";
import { debounce } from "lodash";
import { dummyWallet, networkDetailsByNameKey } from "../../utils/constants";
import { networkDetails, tokens, vaultAddress } from "../EnclaveSDK/EnclaveUtils/constants";
import { enabledNetworks } from "../EnclaveSDK/EnclaveUtils/constants";
import isPWA from "../../utils/pwaUtils";
import { getAggregatedQuote } from "../../utils/swapAdapter";
import { convertToNetworkTokenList } from "../../utils/tokenListUtils";
import { useLocation } from "react-router-dom";
import SwapTokenSelect from "../SwapTokenSelect";
import { ArrowDownwardOutlined } from "@mui/icons-material";
import Numpad from "../Numpad";
import DepositFundModal from "../depositFundModal/depositFundModal";
import { getQuote } from "../../utils/zerox";
import { computeQuote, get0Xquote, getDepositToVaultCalldata, getSmartBalanceConvertCalldata, getWithdrawFromVaultCalldata } from "../EnclaveSDK/EnclaveUtils/functionsV3";
import { getOdosQuote } from "../../utils/odos";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const networkList = Object.values(networkDetailsByNameKey);

export default function SwapPage() {
  const {
    walletAddress,
    balances,
    allTokensList,
    balancesLoading,
    smartBalanceObject,
  } = useEnclaveApi();

  console.log("SMART BALANCE: ", smartBalanceObject);
  const netBalanceDecimals = parseInt(smartBalanceObject.netBalance) / 1000000;

  const query = useQuery();
  const psourceNetwork = query.get("psourceNetwork");
  const pdestinationNetwork = query.get("pdestinationNetwork");

  const psourceAddress = query.get("psourceAddress");
  const pdestinationAddress = query.get("pdestinationAddress");

  const type = query.get("txntype");
  console.log("type: ", type);

  const [sourceNetwork, setSourceNetwork] = useState(
    psourceNetwork
      ? networkDetails[parseInt(psourceNetwork)]
      : networkDetails[enabledNetworks[0]]
  );
  const [destinationNetwork, setDestinationNetwork] = useState(
    pdestinationNetwork
      ? networkDetails[parseInt(pdestinationNetwork)]
      : networkDetails[enabledNetworks[1]]
  );

  const [sourceNetworkBalances, setSourceNetworkBalances] = useState({});
  const [destinationNetworkBalances, setDestinationNetworkBalances] = useState(
    {}
  );

  const sourceTokenList = convertToNetworkTokenList(
    sourceNetwork.id,
    allTokensList
  );
  const destinationTokenList = convertToNetworkTokenList(
    destinationNetwork.id,
    allTokensList
  );

  const [fromToken, setFromToken] = useState(
    !!psourceAddress
      ? sourceTokenList?.find(
          (token) =>
            token.address.toLowerCase() === psourceAddress.toLowerCase()
        )
      : sourceTokenList?.[0]
  );
  const [toToken, setToToken] = useState(
    !!pdestinationAddress
      ? destinationTokenList?.find(
          (token) =>
            token.address.toLowerCase() === pdestinationAddress.toLowerCase()
        )
      : destinationTokenList?.[0]
  );

  const [sourceAmount, setSourceAmount] = useState("");

  const [adjustedInputAmount, setAdjustedInputAmount] = useState("");

  console.log("Adjusted Amount: ", adjustedInputAmount);

  const [destinationAmount, setDestinationAmount] = useState(0);

  const [swapCallData, setSwapCallData] = useState({});

  const [loading, setLoading] = useState(false);

  const [isDepositFundModalOpen, setIsDepositFundModalOpen] = useState(false);

  const validAmount = parseFloat(sourceAmount) > 0;
  const withinBalance = type === 'BUY' ?
    parseFloat(sourceAmount) <= netBalanceDecimals:
    parseFloat(sourceAmount) <=
    parseFloat(sourceNetworkBalances[fromToken?.address.toLowerCase()]?.total);
  const validSwap = validAmount && withinBalance;

  const txnChainId = type === 'BUY' ? destinationNetwork.id : sourceNetwork.id;
  console.log("A. Txn Chain ID: ", txnChainId);
  const balanceByChainId = parseInt(smartBalanceObject.balanceByNetwork.find((balanceObj) => balanceObj.network === txnChainId)?.value ?? 0) / 1000000;
  console.log("B. balanceByChainId: ", balanceByChainId);
  const deficit = (type === 'BUY') ? ((parseFloat(sourceAmount) - balanceByChainId) < 0 ? 0 : parseFloat(sourceAmount) - balanceByChainId) : 0;
  const orderData = (type === 'BUY') ? {
    amount: Math.floor(sourceAmount * 1000000),
    type: "AMOUNT_IN"
  } : undefined;
  console.log("D. orderData: ", orderData);

  useEffect(() => {
    let newFromToken = sourceTokenList?.[0];

    const tempDestinationTokenList = destinationTokenList.filter((token) => {
      if (fromToken && fromToken.chainId === token.chainId) {
        return token.symbol !== fromToken.symbol;
      }
      return true;
    });

    let newToToken = tempDestinationTokenList?.[0];

    if (fromToken) {
      sourceTokenList.forEach((token) => {
        if (
          token.symbol === fromToken.symbol &&
          token.name === fromToken.name
        ) {
          newFromToken = token;
        }
      });
    } else {
      if (psourceAddress) {
        newFromToken = sourceTokenList.find(
          (token) =>
            token.address.toLowerCase() === psourceAddress.toLowerCase()
        );
      }
    }

    if (toToken) {
      tempDestinationTokenList.forEach((token) => {
        if (token.symbol === toToken.symbol && token.name === toToken.name) {
          newToToken = token;
        }
      });
    } else {
      if (pdestinationAddress) {
        newToToken = tempDestinationTokenList.find(
          (token) =>
            token.address.toLowerCase() === pdestinationAddress.toLowerCase()
        );
      }
    }
    setFromToken(newFromToken);
    setToToken(newToToken);
    setSourceNetworkBalances(
      processBalances2(
        balances.filter((balance) => balance.chainId === sourceNetwork.id)
      )
    );
    setDestinationNetworkBalances(
      processBalances2(
        balances.filter((balance) => balance.chainId === destinationNetwork.id)
      )
    );
  }, [sourceNetwork, destinationNetwork, allTokensList]);

  useEffect(() => {
    if (walletAddress) {
      setSourceNetworkBalances(
        processBalances2(
          balances.filter((balance) => balance.chainId === sourceNetwork.id)
        )
      );
      setDestinationNetworkBalances(
        processBalances2(
          balances.filter(
            (balance) => balance.chainId === destinationNetwork.id
          )
        )
      );
    }
  }, [walletAddress, balances]);

  useEffect(() => {
    const fetchData = async () => {
      try {

        let actualInputAmount;

        if (type === 'SELL') {
          actualInputAmount = sourceAmount;
          setAdjustedInputAmount(actualInputAmount);
        } else {
          const usdcQuote = await computeQuote(
            walletAddress,
            destinationNetwork.id,
            Math.floor(parseFloat(sourceAmount) * 1000000),
            "AMOUNT_IN",
          );

          console.log("usdc quote whadup: ", usdcQuote);
          actualInputAmount = (parseFloat(usdcQuote.total_credit) + parseFloat(usdcQuote.userWithdrawal)) / 1000000;
          setAdjustedInputAmount(actualInputAmount);
        }

        let adjustedSourceAmount = parseFloat(actualInputAmount) * 0.995;
        adjustedSourceAmount =
          Math.floor(
            adjustedSourceAmount * Math.pow(10, fromToken.decimals ?? 18)
          ) / Math.pow(10, fromToken.decimals ?? 18);
          
          console.log("adj source amount: ", adjustedSourceAmount);


        // const zeroxQuote = await get0Xquote(
        //   fromToken.address,
        //   toToken.address,
        //   adjustedSourceAmount,
        //   walletAddress,
        //   destinationNetwork.id
        // );

        // console.log("0x Quote :: ", zeroxQuote);

        const aggData = await getAggregatedQuote(
          ethers
            .parseUnits(
              adjustedSourceAmount.toString(),
              fromToken.decimals ?? 18
            )
            .toString(),
          sourceNetwork.id,
          fromToken.address,
          fromToken.symbol,
          destinationNetwork.id,
          toToken.address,
          toToken.symbol,
          walletAddress,
          walletAddress
        );
        console.log({ aggData });
        setSwapCallData(aggData.txn);
        setDestinationAmount(
          parseFloat(aggData.amount.toString()) /
            Math.pow(10, toToken.decimals ?? 18)
        );
      } catch (error) {
        console.error("Error fetching data:", error);
        setDestinationAmount(0);
      } finally {
        setLoading(false);
      }
    };
    const fetchDataDebounce = debounce(fetchData, 1000);
    if (sourceAmount && sourceAmount > 0) {
      setLoading(true);
      fetchDataDebounce();
    } else {
      setDestinationAmount(0);
      setSwapCallData({});
    }

    return () => {
      fetchDataDebounce.cancel();
    };
  }, [sourceAmount, fromToken, toToken]);

  const handleSubmit = async () => {
    try {
      if (!window.enclave) {
        alert("Wallet is not connected");
        return;
      }

      const feeDog0 = parseFloat(adjustedInputAmount.toString()) * 0.005;
      const feeDog1 =
        Math.floor(feeDog0 * Math.pow(10, fromToken.decimals ?? 18)) /
        Math.pow(10, fromToken.decimals ?? 18);
      const feeAmount = ethers.parseUnits(
        feeDog1.toFixed(fromToken.decimals ?? 18).toString(),
        fromToken.decimals ?? 18
      );
      
      console.log("Fee amount: ", feeAmount);

      if (
        fromToken.address.toLowerCase() ===
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        const feeTxn = {
          label: "Fees",
          calldata: "0x",
          targetContractAddress: process.env.REACT_APP_FEE_ADDRESS,
          value: feeAmount,
          chainId: sourceNetwork.id,
          walletAddress: window.enclave.address,
        };

        window.enclave.initiateTransaction(
          [
            feeTxn,
            {
              label: "Crosschain Swap",
              calldata: swapCallData.data,
              targetContractAddress: swapCallData.to,
              value: swapCallData.value,
              chainId: sourceNetwork.id,
              walletAddress: window.enclave.address,
            },
          ],
          `Swap ${sourceAmount} ${
            fromToken.symbol
          } to ${destinationAmount.toFixed(6)} ${toToken.symbol}`,
        );
      } else {

        // Modify based on 0x response
        const transferCallData = transferERC20_CallData(
          process.env.REACT_APP_FEE_ADDRESS,
          fromToken.address,
          sourceNetwork.id,
          feeAmount
        );
        const calldataERC20 = approveAddressERC20_CallData(
          swapCallData.to,
          fromToken.address,
          sourceNetwork.id,
          ethers.parseUnits(sourceAmount.toString(), fromToken.decimals ?? 18)
        );

        // Modify to calldata based on 0x response
        let txnList = [
          {
            label: "Fees ",
            calldata: transferCallData,
            targetContractAddress: fromToken.address,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
            label: "pay fees with input token"
          },
          {
            label: "Approve " + fromToken.symbol,
            calldata: calldataERC20,
            targetContractAddress: fromToken.address,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
            label: "approve token for swap"
          },
          {
            label: "Swap",
            calldata: swapCallData.data,
            targetContractAddress: swapCallData.to,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
            label: "do the actual swap"
          },
        ];

        // if (type === 'BUY' && balanceByChainId > 0) {
        //   const fullBalance = balanceByChainId * 1000000;
        //   console.log("fullBalance: ", fullBalance);
        //   const calldata = getWithdrawFromVaultCalldata(fullBalance, destinationNetwork.id);
        //   txnList = [
        //     {
        //       calldata,
        //       targetContractAddress: vaultAddress,
        //       chainId: destinationNetwork.id,
        //       walletAddress: window.enclave.address,
        //       label: "withdraw from vault"
        //     },
        //     ...txnList
        //   ]
        // } else 
        if (type === 'SELL') {

          console.log("toToken: ", toToken);

          // const approvalCallData = approveAddressERC20_CallData(
          //   vaultAddress,
          //   toToken.address,
          //   destinationNetwork.id,
          //   ethers.parseUnits((destinationAmount * 0.98).toFixed(6).toString(), toToken.decimals ?? 18)
          // );

          // TODO: Change to new deposit all vault contract function
          const depositCallData = getSmartBalanceConvertCalldata(walletAddress, destinationNetwork.id);
          // const depositCallData = getSmartBalanceConvertCalldata(walletAddress, destinationNetwork.id);

          txnList = [
            ...txnList,
            {
              labal: "Deposit in vault",
              calldata: depositCallData,
              targetContractAddress: walletAddress,
              chainId: destinationNetwork.id,
              walletAddress: window.enclave.address
            }
          ]
        }

        console.log("SELL: orderData: ", orderData);

        console.log("DEFICIT: ", deficit);

        window.enclave.initiateTransaction(
          txnList,
          `Swap ${sourceAmount} ${
            fromToken.symbol
          } to ${destinationAmount.toFixed(6)} ${toToken.symbol}`,
          fromToken.address,
          feeAmount,
          orderData,
          deficit <= 0 ? 'GASLESS' : 'ABSTRACTED_INTERNAL'
        );
      }
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  const handleNumberClick = (number) => {
    if (sourceAmount === "0" && number === "0") return;

    if (sourceAmount === "0" && number !== ".") {
      setSourceAmount(number);
      return;
    }

    if ((sourceAmount.charAt(sourceAmount.length - 1) == '.' || sourceAmount.length == 0) && number == '.') {
      return;
    }

    setSourceAmount(sourceAmount + number);
  };
  const handleBackspaceClick = () => {
    setSourceAmount((prevAmount) => prevAmount.slice(0, -1));
  };

  const handlePresetClick = (preset) => {
    const balance =
      type === 'SELL' ?
      (parseFloat(
        sourceNetworkBalances[fromToken.address.toLowerCase()]?.total
      ) || 0) : parseFloat(smartBalanceObject.netBalance) / 1000000;

    if (preset === "MAX") {
      setSourceAmount(balance.toString());
    } else {
      // Convert preset (10, 25, 50, 100) to decimal (0.1, 0.25, 0.5, 1.0)
      const percentage = parseInt(preset) / 100;
      const amount = balance * percentage;
      setSourceAmount(amount.toString());
    }
  };

  return (
    <>
      {isPWA() ? (
        <div className={(styles.page, styles.pageOverrides)}>
          <div
            style={{
              padding: "10px 20px 0 20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2>Trade</h2>
              <div style={{ width: "fit-content" }}>
                {type==='SELL' && <NetworkDropdown
                  networkList={networkList}
                  selectedNetwork={sourceNetwork}
                  setSelectedNetwork={setSourceNetwork}
                />}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "5rem",
                flexDirection: "column",
              }}
            >
              <div>
                <input
                  className={styles.swapInput}
                  value={sourceAmount}
                  type="text"
                  inputMode="decimal"
                  readOnly // Disable typing from native keyboard
                  placeholder="0"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/,/g, ".");
                    if (value.startsWith("00")) {
                      value = value.slice(value.indexOf("0") + 1);
                    } else if (value.startsWith("-")) {
                      value = "0";
                    }
                    if (isNaN(value)) {
                      value = "";
                    }
                    setSourceAmount(value);
                  }}
                  style={{
                    border: "none",
                    background: "transparent",
                    fontSize: "3rem",
                    color: "white",
                    width: `${sourceAmount?.length + 1}ch`,
                    minWidth: "2ch",
                    maxWidth: "180px",
                    // Dynamic width based on content
                    textAlign: "right",
                  }}
                />
                <span
                  className={styles.swapInputSymbol}
                  style={{
                    fontSize: "3rem",
                    color: "gray",
                    marginLeft: "10px",
                    whiteSpace: "nowrap",
                    width: "60%",
                  }}
                >
                  {fromToken?.symbol}
                </span>
              </div>
              <div className={styles.swapBalanceText}>
                <small>
                  Balance:&nbsp;
                  {
                  type === 'BUY' ?
                  netBalanceDecimals :
                  (fromToken &&
                  sourceNetworkBalances[fromToken.address.toLowerCase()]
                    ? parseFloat(
                        sourceNetworkBalances[fromToken.address.toLowerCase()]
                          ?.total
                      )
                    : 0)
                  }
                </small>
              </div>
            </div>

            <div style={{ marginTop: "50px" }}>
              {/* {fromToken && (
                <SwapTokenSelect
                  amount={sourceAmount}
                  type={"Sell"}
                  selectedToken={fromToken}
                  setSelectedToken={setFromToken}
                  tokenList={sourceTokenList}
                  balances={sourceNetworkBalances}
                  networkList={networkList}
                  selectedNetwork={sourceNetwork}
                  setSelectedNetwork={setSourceNetwork}
                />
              )}
              <ArrowDownwardOutlined
                style={{ color: "grey", marginLeft: "35px", marginTop: "5px" }}
              /> */}

              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  bottom: "12vh",
                  padding: "20px",
                  left: "0px",
                }}
              >
                {toToken && (
                  <div style={{ marginBottom: "20px" }}>
                    <SwapTokenSelect
                      amount={destinationAmount}
                      type={"Buy"}
                      selectedToken={toToken}
                      setSelectedToken={setToToken}
                      tokenList={destinationTokenList}
                      balances={destinationNetworkBalances}
                      networkList={networkList}
                      selectedNetwork={destinationNetwork}
                      setSelectedNetwork={setDestinationNetwork}
                    />
                  </div>
                )}
                <Numpad
                  tokenSymbol={fromToken?.symbol}
                  onNumberClick={handleNumberClick}
                  onBackspaceClick={handleBackspaceClick}
                  onPresetClick={handlePresetClick}
                />
                <div style={{ marginTop: "10px" }}>
                  <button
                    className="btn-primary w-full"
                    disabled={
                      !walletAddress ||
                      !ethers.isAddress(walletAddress) ||
                      !validAmount || // Disable when amount is not valid
                      loading || // Disable while loading
                      balancesLoading || // Disable while balances are loading
                      (!validSwap && validAmount && withinBalance) // Only disable for invalid swaps that aren't due to insufficient balance
                    }
                    onClick={
                      !withinBalance
                        ? () => setIsDepositFundModalOpen(true)
                        : handleSubmit
                    }
                  >
                    {walletAddress && ethers.isAddress(walletAddress) ? (
                      loading || balancesLoading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : validSwap ? (
                        "Continue"
                      ) : !validAmount ? (
                        "Enter an amount"
                      ) : !withinBalance ? (
                        "Insufficient balance - Deposit funds"
                      ) : (
                        "Invalid trade"
                      )
                    ) : (
                      "Deposit tokens to trade"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${styles.page} ${isPWA() ? styles.pageOverrides : ""}`}
        >
          <div className={styles.wrapper}>
            <h1>Swap</h1>
            <div className={styles.swapCard}>
              <div className={styles.swapInputWrapper}>
                <div style={{ width: "fit-content" }}>
                  <NetworkDropdown
                    networkList={networkList}
                    selectedNetwork={sourceNetwork}
                    setSelectedNetwork={setSourceNetwork}
                  />
                </div>
                <label>Sell</label>
                <div style={{ display: "flex" }}>
                  <input
                    className={styles.swapInput}
                    value={sourceAmount}
                    type="text"
                    inputMode="decimal"
                    placeholder="0"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/,/g, ".");
                      if (value.startsWith("00")) {
                        value = value.slice(value.indexOf("0") + 1);
                      } else if (value.startsWith("-")) {
                        value = "0";
                      }
                      if (isNaN(value)) {
                        value = "";
                      }
                      setSourceAmount(value);
                    }}
                  />

                  {fromToken && (
                    <Dropdown
                      selectedToken={fromToken}
                      setSelectedToken={setFromToken}
                      tokenList={sourceTokenList}
                      balances={sourceNetworkBalances}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: 16,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <div>
                    <small>
                      Balance:&nbsp;
                      {fromToken &&
                      sourceNetworkBalances[fromToken.address.toLowerCase()]
                        ? parseFloat(
                            sourceNetworkBalances[
                              fromToken.address.toLowerCase()
                            ]?.total
                          )
                        : 0}
                    </small>
                  </div>
                  <div>
                    &nbsp;
                    <button
                      className="btn-ghost sm"
                      onClick={() => {
                        parseFloat(
                          sourceNetworkBalances[fromToken.address.toLowerCase()]
                            ?.total
                        )
                          ? setSourceAmount(
                              parseFloat(
                                sourceNetworkBalances[
                                  fromToken.address.toLowerCase()
                                ]?.total
                              )
                            )
                          : setSourceAmount(0);
                      }}
                    >
                      MAX
                    </button>
                  </div>
                </div>
              </div>
              <div className={styles.swapInputWrapper}>
                <div style={{ width: "fit-content" }}>
                  <NetworkDropdown
                    networkList={networkList}
                    selectedNetwork={destinationNetwork}
                    setSelectedNetwork={setDestinationNetwork}
                  />
                </div>
                <label>Buy</label>
                <div style={{ display: "flex" }}>
                  <input
                    className={styles.swapInput}
                    type="number"
                    inputMode="decimal"
                    placeholder="0"
                    value={destinationAmount}
                    disabled
                  />
                  {toToken && (
                    <Dropdown
                      selectedToken={toToken}
                      setSelectedToken={setToToken}
                      tokenList={destinationTokenList.filter((token) => {
                        if (fromToken.chainId === token.chainId) {
                          return token.symbol !== fromToken.symbol;
                        }
                        return true;
                      })}
                      balances={destinationNetworkBalances}
                    />
                  )}
                </div>
                <small>
                  Balance:&nbsp;
                  {toToken &&
                  destinationNetworkBalances[toToken.address.toLowerCase()]
                    ? parseFloat(
                        destinationNetworkBalances[
                          toToken.address.toLowerCase()
                        ]?.total
                      )
                    : 0}
                </small>
              </div>

              <button
                className="btn-primary w-full"
                disabled={
                  !walletAddress ||
                  !ethers.isAddress(walletAddress) ||
                  !validAmount || // Disable when amount is not valid
                  loading || // Disable while loading
                  balancesLoading || // Disable while balances are loading
                  (!validSwap && validAmount && withinBalance) // Only disable for invalid swaps that aren't due to insufficient balance
                }
                onClick={
                  !withinBalance
                    ? () => setIsDepositFundModalOpen(true)
                    : handleSubmit
                }
              >
                {walletAddress && ethers.isAddress(walletAddress) ? (
                  loading || balancesLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : validSwap ? (
                    "Swap"
                  ) : !validAmount ? (
                    "Enter an amount"
                  ) : !withinBalance ? (
                    "Insufficient balance"
                  ) : (
                    "Invalid swap"
                  )
                ) : (
                  "Sign in to swap"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      {isDepositFundModalOpen && (
        <DepositFundModal
          isOpen={isDepositFundModalOpen}
          onClose={() => setIsDepositFundModalOpen(false)}
        />
      )}
    </>
  );
}
