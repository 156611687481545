import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register necessary components with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const networkColors = {
    10: 'rgba(232, 65, 66, 1)',
    42161: 'rgba(0, 119, 255, 1)',
}


const DonutChart = (props) => {

    const networks = Object.keys(props.networkBalances);
    const balanceData = networks.map((network) => props.networkBalances[network].amount);
    const backgroundColor = networks.map((network) => networkColors[network]);
    const borderColor = networks.map((network) => networkColors[network]);

    const data = {
        datasets: [
            {
                label: props.name,
                data: balanceData,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        cutout: '70%', // This controls the size of the "hole" in the middle
        plugins: {
            legend: {
                position: 'bottom', // You can adjust this position
            },
            tooltip: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        console.log("TOOLTIP: ", tooltipItem, data);
                        const label = tooltipItem.dataset.label;
                        const value = tooltipItem.raw.toFixed(6);
                        return `${label}: ${value}`;
                    }
                }
            }
        },
    };

    return <Doughnut data={data} options={options} />;
};

export default DonutChart;
