import { toBigInt } from "ethers";
import { getCallData } from "./bungeeFunction";
import { getRelayQuote } from "./relayFunctions"
import { getRouterCallData } from "./routerFunctions";
import { getSwingQuote } from "./swingFunction";
import { dummyWallet } from "./constants";

export const getAggregatedQuote = async (
    amount, // string
    fromChainId,
    fromTokenAddr,
    fromTokenSymbol,
    toChainId,
    toTokenAddr,
    toTokenSymbol,
    userAddr,
    receiverAddr
) => {

    console.log("FETCHING AGGREGATED QUOTE");

    console.log("AMOUNT: ", amount);
    console.log(fromChainId,
        fromTokenAddr,
        fromTokenSymbol,
        toChainId,
        toTokenAddr,
        toTokenSymbol)
    const walletAddr = (!!userAddr && userAddr.length > 0) ? userAddr : dummyWallet

    const relayQuotePromise = getRelayQuote(amount, fromChainId, fromTokenAddr, toChainId, toTokenAddr, walletAddr);
    // const swingQuotePromise = getSwingQuote(amount, fromChainId, fromTokenAddr, fromTokenSymbol, toChainId, toTokenAddr, toTokenSymbol, userAddr, receiverAddr);
    const socketQuotePromise = getCallData(fromChainId, fromTokenAddr, toChainId, toTokenAddr, amount, walletAddr, walletAddr);
    // const routerQuotePromise = getRouterCallData(amount, fromTokenAddr, fromChainId, toTokenAddr, toChainId, walletAddr);

    const [
        relayQuote, 
        // swingQuote, 
        socketQuote, 
        // routerQuote
    ] = await Promise.all([
        relayQuotePromise, 
        // swingQuotePromise, 
        socketQuotePromise, 
        // routerQuotePromise
    ]);

    console.log("RELAY QUOTE: ", relayQuote);
    console.log("SOCKET QUOTE: ", socketQuote);
    // console.log("ROUTER QUOTE: ", routerQuote);

    const relayOut = relayQuote.error ? 0 : toBigInt(relayQuote.details.currencyOut.amount);
    const socketOut = socketQuote.error ? 0 : toBigInt(socketQuote.route.toAmount);
    // const routerOut = routerQuote.error ? 0 : toBigInt(routerQuote.destination.tokenAmount);

    const relayTxn = relayQuote.error ? null : {
        ...relayQuote.steps[relayQuote.steps.length - 1].items[0].data
    };
    const socketTxn = socketQuote.error ? null : { 
        to: socketQuote.apiReturnData.result.txTarget, 
        value: socketQuote.apiReturnData.result.value, 
        data: socketQuote.apiReturnData.result.txData
    };

    // const routerTxn =  routerQuote.error ? null : {
    //     to: routerQuote.txn.to,
    //     value: routerQuote.txn.value,
    //     data: routerQuote.txn.data
    // };

    const amounts = [
        relayOut, 
        socketOut, 
        // routerOut
    ];
    const txns = [
        relayTxn, 
        socketTxn, 
        // routerTxn
    ];
    const providers = [
        "relay", "socket", 
        "router"
    ];

    const max = amounts.reduce((max, current) => (current > max ? current : max), toBigInt(0)); // Find the maximum BigInt
    const maxIndex = amounts.indexOf(max); // Get the index of the maximum value

    console.log("MAX: ", max);
    return {
        amount: max,
        txn: txns[maxIndex],
        provider: providers[maxIndex],
        fromChainId,
        fromTokenAddr,
        fromTokenSymbol,
        toChainId,
        toTokenAddr,
        toTokenSymbol
    }
}
