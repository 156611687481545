import axios from 'axios';
import { API_KEY, BASE_URL } from './base';

export async function getActionById(actionId) {
  try {
    const response = await axios.get(`https://api.stakek.it/v1/actions/${actionId}`, {
      headers: {
        'accept': 'application/json',
        'X-API-KEY': API_KEY
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching action:', error);
    throw error;
  }
}

export async function getActionGasEstimate(actionId) {
  try {
    const response = await axios.get(`${BASE_URL}/actions/${actionId}/gas-estimate`, {
      headers: {
        'accept': 'application/json',
        'X-API-KEY': API_KEY
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching action gas estimate:', error);
    throw error;
  }
}

export async function enterAction({
  integrationId,
  addresses,
  args
}) {
  try {
    const response = await axios.post(`${BASE_URL}/v1/actions/enter`, {
      integrationId,
      addresses,
      args
    }, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        'X-API-KEY': API_KEY
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error entering action:', error);
    throw error;
  }
}

export async function exitAction({
  integrationId,
  addresses,
  args
}) {
  try {
    const response = await axios.post(`${BASE_URL}/v1/actions/exit`, {
      integrationId,
      addresses,
      args
    }, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        'X-API-KEY': API_KEY
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error exiting action:', error);
    throw error;
  }
}

export async function getPendingActions(type) {
  try {
    const response = await axios.post(`${BASE_URL}/v1/actions/pending`, { type }, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        'X-API-KEY': API_KEY
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching pending actions:', error);
    throw error;
  }
}

export async function estimateEnterActionGas(data) {
  try {
    const response = await axios.post(`${BASE_URL}/v1/actions/enter/estimate-gas`, data, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        'X-API-KEY': API_KEY
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error estimating enter action gas:', error);
    throw error;
  }
}

export async function estimateExitActionGas(data) {
  try {
    const response = await axios.post(`${BASE_URL}/v1/actions/exit/estimate-gas`, data, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        'X-API-KEY': API_KEY
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error estimating exit action gas:', error);
    throw error;
  }
}

export async function getAllActions() {
  try {
    const response = await axios.get(`${BASE_URL}/v1/actions`, {
      headers: {
        'accept': 'application/json',
        'X-API-KEY': API_KEY
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching all actions:', error);
    throw error;
  }
}

export async function estimatePendingActionGas(data) {
  try {
    const response = await axios.post(`${BASE_URL}/v1/actions/pending/estimate-gas`, data, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        'X-API-KEY': API_KEY
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error estimating pending action gas:', error);
    throw error;
  }
}

