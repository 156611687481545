// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMx29zx9dtrSvLX_4KcPWOc15KvPxoFNc",
  authDomain: "endeavour-alpha.firebaseapp.com",
  projectId: "endeavour-alpha",
  storageBucket: "endeavour-alpha.firebasestorage.app",
  messagingSenderId: "587036527388",
  appId: "1:587036527388:web:50dbe0e40bcaed923f48d9",
  measurementId: "G-5KJQ45TS3S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export { analytics, messaging }