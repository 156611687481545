import { MenuSharp } from "@mui/icons-material";
import "./css/topbar.css";
import Connect from "./EnclaveSDK/Connect";
import { NavList } from "./nav-list";
import { useRef } from "react";
import EndevourLogo from "./Endeavour1.png";
import isPWA from "../utils/pwaUtils";

export const Topbar = () => {
	const toggleCheckboxRef = useRef();
	return (
		<>
			<header>
				<input ref={toggleCheckboxRef} type="checkbox" id="menu-toggle" />
				{
					!isPWA() && 
					<label htmlFor="menu-toggle" className="hamburger-btn">
						<MenuSharp />
					</label>
				}
				<div className="logo-wrapper">
					{/* <EndeavourLogo /> */}
					<div>
						<img src={EndevourLogo} width={144} alt="Endeavour" />
					</div>
				</div>
				<Connect />
				<label htmlFor="menu-toggle" className="mobile-menu-overlay"></label>
				<nav className="mobile-menu">
					<NavList
						onSelectEnd={() => {
							toggleCheckboxRef.current.checked = false;
						}}
					/>
				</nav>
			</header>
		</>
	);
};
