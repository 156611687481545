import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./depositFundModal.module.css";
import { HandCoinsIcon, ArrowUpRight } from "lucide-react";

export default function DepositFundModal({ isOpen, onClose }) {
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleCashClick = () => {
    navigate("/buy");
  };

  const handleCryptoClick = () => {
    navigate("/account");
  };

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modalContent}>
        <div className={styles.handCoinsIcon}>
          <HandCoinsIcon />
        </div>
        <h2>Deposit Funds</h2>

        <div className={styles.optionCards}>
          <div className={styles.card} onClick={handleCashClick}>
            <div className={styles.cardContent}>
              <h3>Cash ($)</h3>
              <p>Deposit funds using cards or bank transfer.</p>
            </div>
            <div className={styles.arrowCircle}>
              <ArrowUpRight size={16} />
            </div>
          </div>

          <div className={styles.card} onClick={handleCryptoClick}>
            <div className={styles.cardContent}>
              <h3>Crypto</h3>
              <p>Receive tokens from another crypto wallet.</p>
            </div>
            <div className={styles.arrowCircle}>
              <ArrowUpRight size={16} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
