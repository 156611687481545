export const API_KEY = process.env.REACT_APP_STAKEKIT_API_KEY; // Replace with your actual API key

export const BASE_URL = "https://api.stakek.it";

export async function getStakeOpportunities() {
  try {
    console.log("Fetching stake opportunities...");
    const response = await fetch('https://api.stakek.it/v1/yields', {
      method: 'GET',
      headers: {
        'X-API-KEY': API_KEY
      }
    });
    console.log("response: ", response);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching stake opportunities:', error);
    return error;
  }
}

