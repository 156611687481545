// Modal.js
import React from "react";
import styles from "./networkModal.module.css"; // Create a CSS module for styling
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import { networkDetails } from "../EnclaveSDK/EnclaveUtils/constants";
import { Globe } from "lucide-react";

const NetworkModal = ({ isOpen, onClose }) => {
  const { userData } = useEnclaveApi();

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleChipClick = (explorerLink, walletAddress) => {
    window.open(
      explorerLink + "address/" + userData.wallet.scw_address,
      "_blank",
      "noopener,noreferrer"
    ); // Open link in a new tab
  };

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modalContent}>
        <div className={styles.globeIcon}>
          <Globe />
        </div>
        <h2>Supported Networks</h2>
        <div className={styles.networkList}>
          {userData.wallet.multi_scw.map((details, index) => (
            <div
              key={index}
              className={styles.networkChip}
              onClick={() =>
                handleChipClick(networkDetails[details.network].explorer)
              }
            >
              <img
                src={networkDetails[details.network].image}
                alt={networkDetails[details.network].name}
              />
              <span>{networkDetails[details.network].name}</span>
            </div>
          ))}
        </div>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default NetworkModal;
