import { ethers } from "ethers";
import { approveAddressERC20_CallData } from "./functions";
import { usdcPaymasterMap } from "./constants";
import { tokens } from "./constants";

export const setWindowEnclave = (userData) => {
    window.enclave = {
        address: userData.wallet.scw_address,
        initiateTransaction: (transactions, label, feeToken, feeTokenAmount, orderData, gasMode = 'GASLESS') => {
            window.enclave.transactionDetails.label = label;
            window.enclave.transactionDetails.internalTxns = transactions.map((transaction, index) => ({
                index: index,  // Get the index of the transaction in the transactions array,
                label: transaction.label,
                calldata: transaction.calldata,
                contractAddress: transaction.targetContractAddress,
                chainId: transaction.chainId,
                value: transaction.value?.toString(),
                status: "PENDING",
                transactionHash: null,
                transactionOject: null
            }));
            window.enclave.transactionDetails.feeToken = feeToken;
            window.enclave.transactionDetails.feeTokenAmount = feeTokenAmount;
            window.enclave.transactionDetails.orderData = orderData;
            window.enclave.transactionDetails.gasMode = gasMode;
            window.dispatchEvent(new Event('openTransactionPopUp'));
        },
        transactionDetails: {
            label: null,
            internalTxns: [],
            feeToken: null,
            feeTokenAmount: null
        }
    }
}