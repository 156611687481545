import React, { useState, useRef, useEffect } from "react";
import styles from "./css/swapDropdown.module.css"; // Import your CSS module
import { Search } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { networkDetailsByNameKey } from "../utils/constants";

const OppDropdown = (props) => {
	const [searchTerm, setSearchTerm] = useState("");
	const dropdownRef = useRef(null);
	const [filteredOpps, setFilteredOpps] = useState(props.opportunities);

	// Sort opps by apy
	const sortedOpportunities = filteredOpps.sort((a, b) => {
		return b.apy - a.apy;
	});

	const selectOpp = (opp) => {
		props.setSelectedOpp(opp);
		props.setIsDropdownOpen(false); // Close dropdown after selection
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				props.setIsDropdownOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [filteredOpps]);

	useEffect(() => {
		setFilteredOpps(
			props.opportunities.filter((opp) => {
				return opp.metadata.name.toLowerCase().startsWith(searchTerm.toLowerCase());
			}),
		);
	}, [searchTerm, props.opportunities]);

	return (
		<div className={styles.main}>
			<div className={styles.dropdownMenu} ref={dropdownRef}>
				<h2>
					<span onClick={() => props.setIsDropdownOpen(false)}><ArrowBackIcon /></span>
					Select
				</h2>
				<input
					type="text"
					placeholder="Search .."
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
				<ul className={styles.options}>
					{
						sortedOpportunities.length == 0 &&
						<div className={styles.emptyList}>
							<Search />
							<br />
							No opportunities found
						</div>
					}
					{sortedOpportunities.map((opp) => {
						return (
							<li key={opp.metadata.name+opp.token.network} className={styles.dropdownItem} onClick={() => selectOpp(opp)}>
								<div className={styles.tokenDetails}>
									{
									<img width={24} height={24} src={opp?.metadata?.provider?.logoURI ?? networkDetailsByNameKey[opp.token.network].logo} 
									onError={(e) => {
										e.target.onerror = null; // prevents looping
										e.target.src = networkDetailsByNameKey[opp.token.network].logo;
										}} />
									}
									<div>
										<span>{opp?.metadata?.name}</span><br/>
										<div className="tag">
											<div className="dot" />
											&nbsp; {networkDetailsByNameKey[opp.token.network].name}
										</div>
									</div>
								</div>

								<div>
										<span>{(opp.apy * 100).toFixed(2)} %</span><br />
										{/* {opp?.apy === props.opportunities[0]?.apy ? 
											<div className="tag green">&nbsp; HIGHEST RATE</div>
										: <div></div>} */}
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default OppDropdown;
