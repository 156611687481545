import styles from "./css/sidebar.module.css";
import { NavList } from "./nav-list";
import EndevourLogo from "./Endeavour1.png";

export const Sidebar = () => {
	return (
		<aside className={styles.sidebarWrapper}>
			<div className={styles.sidebar}>
				<div className={styles.logoWrapper}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<img src={EndevourLogo} width={200} alt="Endeavour" />
					</div>
				</div>
				<ul className={styles.navList}>
					<NavList />
				</ul>
				<div style={{ alignSelf: "center" }} />
			</div>
		</aside>
	);
};
