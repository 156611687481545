import axios from "axios";
import { dummyWallet } from "./constants";

const BASE_URL = "https://api.relay.link/quote";

export const getRelayQuote = async (
    amount, 
    fromChainId, 
    fromChainAddr, 
    toChainId, 
    toChainAddr, 
    userAddr
) => {
    try {
        const response = await axios.post(BASE_URL, {
            user: userAddr ?? dummyWallet,
            originChainId: fromChainId,
            destinationChainId: toChainId,
            originCurrency: fromChainAddr,
            destinationCurrency: toChainAddr,
            amount: amount.toString(),
            tradeType: "EXACT_INPUT"
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        // console.error('Error fetching relay quote:', error);
        return {
            error: 1,
            errorObj: error
        }
    }
}

export const getRelayQuoteAmountIn = async (
    amount, 
    fromChainId, 
    fromChainAddr, 
    toChainId, 
    toChainAddr, 
    userAddr
) => {
    try {
        const response = await axios.post(BASE_URL, {
            user: userAddr ?? dummyWallet,
            originChainId: fromChainId,
            destinationChainId: toChainId,
            originCurrency: fromChainAddr,
            destinationCurrency: toChainAddr,
            amount: amount.toString(),
            tradeType: "EXACT_OUTPUT"
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        // console.error('Error fetching relay quote:', error);
        return {
            error: 1,
            errorObj: error
        }
    }
}

export const getTokenPrice = async (chainId, address) => {
    try {
        const response = await axios.get("https://api.relay.link/currencies/token/price", {
            params: {
                address,
                chainId
            }
        });

        return response.data;
    } catch (e) {
        console.error("Error fetching price: ", e);
    }
}