import { useEffect, useState, useRef } from "react";
import styles from "./css/transaction.module.css";
import {
  getMultiGasFees,
  submitMultiTransaction,
} from "./EnclaveUtils/functionsV3";
import { CircularProgress } from "@mui/material";
import { enabledNetworks, networkDetails } from "./EnclaveUtils/constants";
import {
  useEnclaveApi,
  useEnclaveConnect,
} from "./context/EnclaveConnectProvider";
import { processBalances } from "../../utils/functions";
import { isMember } from "../../utils/gasNFT";
import isPWA from "../../utils/pwaUtils";
import { getUser } from "./EnclaveUtils/functions";
import { getExplorerUrl } from "./EnclaveUtils/functions";
import { debounce } from "lodash";
import dollarlogo from "../../assets/dollar_jpeg 1.png";

const spliceAddress = (address) => {
  return address.slice(0, 8) + "..." + address.slice(-4);
};

const gasModes = {
  NATIVE: "NATIVE",
  USDC: "USDC",
  USDT: "USDT",
  USDC_OVERRIDE: "USDC_OVERRIDE",
  USDT_OVERRIDE: "USDT_OVERRIDE",
  ETH_OVERRIDE: "ETH_OVERRIDE",
  INSUFFICIENT_BALANCE: "INSUFFICIENT_BALANCE",
  GASLESS: "GASLESS",
  ABSTRACTED_INTERNAL: "ABSTRACTED_INTERNAL",
};

export default function Transaction(props) {
  const bg = useRef(null);
  const { walletAddress, fetchBalances, balances, userData, allTokensList } =
    useEnclaveApi();
  const { setUserData } = useEnclaveConnect();
  const [transactionState, setTransactionState] = useState("pending");
  const [gasFeeLoading, setGasFeeLoading] = useState(true);
  const [txnResult, setTxnResult] = useState({});
  const [viewInternal, setViewInternal] = useState(false);
  const [gasFeeError, setGasFeeError] = useState(false);

  const [gasFeeObject, setGasFeeObject] = useState({
    NATIVE: 0,
    USDC: 0,
    USDT: 0,
    // sponsored: false
    sponsored: true, // Permenantly set to true for gasless transactions based on input token fee
  });

  const internalTxns = window.enclave.transactionDetails.internalTxns;
  const chainId = internalTxns[0].chainId;

  const chainDetails = networkDetails[chainId];

  const processedBalances = processBalances(
    balances.filter((balance) => enabledNetworks.includes(balance.chainId))
  );

  const canExecuteTransaction = gasFeeObject.sponsored;

  console.log();

  const calculateGasFees = async () => {
    setGasFeeLoading(true);

    // Calculate gas fee for ETH transactions
    const ethGasFeeResultPromise = getMultiGasFees(
      userData.username,
      window.enclave.address,
      internalTxns.map((txn) => ({
        encodedData: txn.calldata,
        targetContractAddress: txn.contractAddress,
        value: txn.value,
        label: txn.label,
      })),
      chainId,
      window.enclave.transactionDetails.gasMode ??
        (window.enclave.transactionDetails.orderData
          ? gasModes.ABSTRACTED_INTERNAL
          : gasModes.GASLESS),
      window.enclave.transactionDetails.orderData
    );

    const memberPromise = isMember(userData.wallet?.scw_address);

    let ethGasFeeResult, memberResult;

    try {
      console.log("A. CALCULATING GAS FEES");
      [ethGasFeeResult, memberResult] = await Promise.all([
        ethGasFeeResultPromise,
        memberPromise,
      ]);
      console.log("B. GAS FEES: ", ethGasFeeResult);
    } catch (error) {
      console.log("C. Error fetching gas fees: ", error);
      setGasFeeError(true);
      return;
    }

    setGasFeeObject({
      NATIVE: ethGasFeeResult.error
        ? parseFloat(ethGasFeeResult.value) / 10 ** 18
        : parseFloat(ethGasFeeResult.result) / 10 ** 18,
      sponsored: true, // Permenantly set to true for gasless transactions based on input token fee
    });

    setGasFeeLoading(false);
  };

  const debouncedCalculateGasFees = useRef(
    debounce(calculateGasFees, 500) // 500ms delay
  ).current;

  useEffect(() => {
    bg.current.addEventListener("click", (e) => {
      if (e.target === bg.current) {
        props.setTransactionPopUp(false);
      }
    });
  }, []);

  useEffect(() => {
    debouncedCalculateGasFees();

    return () => {
      debouncedCalculateGasFees.cancel(); // Cancel any pending debounced calls on cleanup
    };
  }, []);

  const executeTransaction = () => {
    setTransactionState("processing");
    submitMultiTransaction(
      props.userData.username,
      internalTxns.map((txn) => ({
        encodedData: txn.calldata,
        targetContractAddress: txn.contractAddress,
        value: txn.value,
        label: txn.label,
      })),
      chainId,
      window.enclave.transactionDetails.label,
      window.enclave.transactionDetails.gasMode ??
        (window.enclave.transactionDetails.orderData
          ? gasModes.ABSTRACTED_INTERNAL
          : gasModes.GASLESS),
      window.enclave.transactionDetails.feeToken,
      window.enclave.transactionDetails.feeTokenAmount,
      window.enclave.transactionDetails.orderData
    ).then((res) => {
      console.log("TXN: ", res);
      setTxnResult(res);

      const updateBalances = () => {
        fetchBalances().then((res) => {
          setTransactionState("completed");
          props.setTransactionPopUp(false);
          getUser(props.userData.username).then((res) => {
            setUserData(res);
          });
        });
      };
      setTimeout(updateBalances, 1000);
      window.dispatchEvent(new Event("completedTransaction", { txnRes: res }));
    });
  };

  console.log("Gas Fee Error: ", gasFeeError);
  console.log("LABEL: ", window.enclave.transactionDetails.label.split(" ")[2]);

  return (
    <main ref={bg} className={styles.main}>
      <div
        className={`${styles.popUp} ${isPWA() ? styles.popUpOverrides : ""}`}
      >
        {/* <div className={styles.heading}>
          <h1 style={{ textAlign: "center" }}>
            {window.enclave.transactionDetails.label.includes("Swap")
              ? window.enclave.transactionDetails.label.split(" ")[2] === "USDC"
                ? `Buying ${
                    window.enclave.transactionDetails.label.split(" ")[4]
                  } ${
                    window.enclave.transactionDetails.label.split(" ")[5]
                  } for ${
                    window.enclave.transactionDetails.label.split(" ")[1]
                  }$`
                : `Selling ${
                    window.enclave.transactionDetails.label.split(" ")[1]
                  } ${
                    window.enclave.transactionDetails.label.split(" ")[2]
                  } for ${
                    window.enclave.transactionDetails.label.split(" ")[4]
                  }$`
              : window.enclave.transactionDetails.label.includes("Send")
              ? `Sending ${window.enclave.transactionDetails.label
                  .split(" ")
                  .slice(1)
                  .join(" ")}`
              : window.enclave.transactionDetails.label}
          </h1>
        </div> */}

        <div>
          {" "}
          {window.enclave.transactionDetails.label.includes("Swap") ? (
            window.enclave.transactionDetails.label.split(" ")[2] === "USDC" ? (
              <>
                <h3 className={styles.details}> You are buying </h3>

                <br></br>

                <div className={styles.transactionAmount}>
                  <div>
                    <h2>
                      {window.enclave.transactionDetails.label.split(" ")[4]}

                      {window.enclave.transactionDetails.label.split(" ")[5]}
                    </h2>

                    <h3 className={styles.details} style={{ marginTop: "4px" }}>
                      for{" "}
                      {window.enclave.transactionDetails.label.split(" ")[1]}$
                    </h3>
                  </div>

                  <div className={styles.tokenLogoContainer}>
                    {window.enclave.transactionDetails.label.split(" ")[2] ===
                      "USD" && (
                      <img className={styles.tokenLogo} src={dollarlogo} />
                    )}
                    {window.enclave.transactionDetails.label.split(" ")[2] !=
                      "USD" && (
                      <img
                        className={styles.tokenLogo}
                        src={
                          window.enclave.transactionDetails.label.includes(
                            "Swap"
                          )
                            ? window.enclave.transactionDetails.label.split(
                                " "
                              )[2] === "USDC"
                              ? allTokensList.find(
                                  (token) =>
                                    token.symbol ===
                                    window.enclave.transactionDetails.label.split(
                                      " "
                                    )[5]
                                )?.logoURI
                              : allTokensList.find(
                                  (token) =>
                                    token.symbol ===
                                    window.enclave.transactionDetails.label.split(
                                      " "
                                    )[2]
                                )?.logoURI
                            : allTokensList.find(
                                (token) =>
                                  token.symbol ===
                                  window.enclave.transactionDetails.label.split(
                                    " "
                                  )[2]
                              )?.logoURI
                        }
                        alt={chainDetails.name}
                      />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <h3 className={styles.details}> You are selling </h3>

                <br></br>

                <div className={styles.transactionAmount}>
                  <div>
                    <h2>
                      {window.enclave.transactionDetails.label.split(" ")[1]}

                      {window.enclave.transactionDetails.label.split(" ")[2]}
                    </h2>

                    <h3 style={{ marginTop: "2px" }} className={styles.details}>
                      for{" "}
                      {window.enclave.transactionDetails.label.split(" ")[4]}$
                    </h3>
                  </div>

                  <div className={styles.tokenLogoContainer}>
                    {window.enclave.transactionDetails.label.split(" ")[2] ===
                      "USD" && (
                      <img className={styles.tokenLogo} src={dollarlogo} />
                    )}
                    {window.enclave.transactionDetails.label.split(" ")[2] !=
                      "USD" && (
                      <img
                        className={styles.tokenLogo}
                        src={
                          window.enclave.transactionDetails.label.includes(
                            "Swap"
                          )
                            ? window.enclave.transactionDetails.label.split(
                                " "
                              )[2] === "USDC"
                              ? allTokensList.find(
                                  (token) =>
                                    token.symbol ===
                                    window.enclave.transactionDetails.label.split(
                                      " "
                                    )[5]
                                )?.logoURI
                              : allTokensList.find(
                                  (token) =>
                                    token.symbol ===
                                    window.enclave.transactionDetails.label.split(
                                      " "
                                    )[2]
                                )?.logoURI
                            : allTokensList.find(
                                (token) =>
                                  token.symbol ===
                                  window.enclave.transactionDetails.label.split(
                                    " "
                                  )[2]
                              )?.logoURI
                        }
                        alt={chainDetails.name}
                      />
                    )}
                  </div>
                </div>
              </>
            )
          ) : window.enclave.transactionDetails.label.includes("Send") ? (
            <>
              <h3 className={styles.details}> You are sending </h3>

              <br></br>

              <div className={styles.transactionAmount}>
                <h2>
                  {window.enclave.transactionDetails.label

                    .split(" ")

                    .slice(1)

                    .join(" ")}
                </h2>

                <div className={styles.tokenLogoContainer}>
                  {window.enclave.transactionDetails.label.split(" ")[2] ===
                    "USD" ? (
                    <img alt="USD" className={styles.tokenLogo} src={dollarlogo} />
                  ) : (
                    <img
                      className={styles.tokenLogo}
                      src={
                        window.enclave.transactionDetails.label.includes("Swap")
                          ? window.enclave.transactionDetails.label.split(
                              " "
                            )[2] === "USDC"
                            ? allTokensList.find(
                                (token) =>
                                  token.symbol ===
                                  window.enclave.transactionDetails.label.split(
                                    " "
                                  )[5]
                              )?.logoURI
                            : allTokensList.find(
                                (token) =>
                                  token.symbol ===
                                  window.enclave.transactionDetails.label.split(
                                    " "
                                  )[2]
                              )?.logoURI
                          : allTokensList.find(
                              (token) =>
                                token.symbol ===
                                window.enclave.transactionDetails.label.split(
                                  " "
                                )[2]
                            )?.logoURI
                      }
                      alt={chainDetails.name}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            window.enclave.transactionDetails.label
          )}
        </div>
        <br />
        {window.enclave.transactionDetails.label.includes("Send") ? null : (
          <div className={styles.details}>
            <h3>Transaction Fees: </h3>

            <h3>
              {0.05 *
                Number(window.enclave.transactionDetails.label.split(" ")[4])}

              {window.enclave.transactionDetails.label.split(" ")[5]}
            </h3>
          </div>
        )}

        {/* <div>
                    Transaction Batch ({txnSet.length})
                    <span className={styles.viewInternalToggle} onClick={
                        () => {
                            setViewInternal(!viewInternal);
                        }
                    }>
                        {viewInternal ? "Hide" : "View"}
                    </span>
                </div> */}
        {viewInternal && (
          <>
            {internalTxns.map((txn, index) => (
              <div className={styles.internalContainer} key={index}>
                <div className={styles.internalHeading}>
                  {txn.label} ({index + 1} / {internalTxns.length})
                </div>
                <div className={styles.internalHeading}>
                  <h2> From </h2>
                  <h2> To </h2>
                </div>

                <div className={styles.transaction}>
                  <div className={styles.fromContainer}>
                    <div className={`${styles.circle} ${styles.blue}`}></div>
                    <h2>{spliceAddress(walletAddress)}</h2>
                  </div>

                  <img src="/extras/transfer.svg" alt="Transfer" />
                  <div className={styles.fromContainer}>
                    <div className={`${styles.circle} ${styles.lime}`}></div>
                    <h2>{spliceAddress(txn.contractAddress)}</h2>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {/* <div className={styles.heading}>
                    <h1>Transaction Details</h1>
                    <h1> </h1>
                </div>

                <div className={styles.details}>
                    <h2>Value</h2>
                    <h2>
                        {totalValueOfInternalTxns / 1e18} {chainDetails.nativeToken}
                    </h2>
                </div> */}

        {/* {
                    gasFeeObject.sponsored &&
                    <div className={styles.details}>
                        <h3 style={{color: "green", backgroundColor: "lightgreen", padding: "4px", borderRadius: "4px"}}>Sponsored</h3>
                    </div>
                } */}

        <div className={styles.details}>
          {gasFeeError && (
            <h4 className={styles.gasError}>Transaction will fail</h4>
          )}
        </div>
        {transactionState === "pending" &&
          !gasFeeLoading &&
          canExecuteTransaction &&
          !gasFeeError && (
            <div className={styles.btnCon2}>
              {/* <button className={styles.button}
                            onClick={() => {
                                props.setTransactionPopUp(false)
                            }}>
                            <h1>Cancel</h1>
                        </button> */}
              <button className={styles.button2} onClick={executeTransaction}>
                <h1>Confirm</h1>
              </button>
            </div>
          )}
        {(transactionState === "processing" || gasFeeLoading) &&
          transactionState !== "completed" && (
            <div className={styles.btnCon}>
              <button className={styles.button3} disabled={true}>
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ color: "#111" }}
                />
              </button>
            </div>
          )}
        {transactionState === "completed" && txnResult.txnHash && (
          <div className={styles.btnCon}>
            <button
              onClick={() => {
                window.open(
                  `${getExplorerUrl(
                    window.enclave.transactionDetails.internalTxns[0].chainId
                  )}${txnResult.txnHash}`
                );
              }}
              className={styles.button3}
            >
              <h1>View on Explorer</h1>
              <img
                className={styles.arrow}
                src="/icons/arrow.svg"
                alt="Arrow"
              />
            </button>
          </div>
        )}
        {transactionState === "completed" && txnResult.error && (
          <div>
            <h4>Error: {txnResult.error}</h4>
          </div>
        )}
      </div>
    </main>
  );
}
